<template>
  <div style="position: relative!important;">
    <div class="loader-wrap" v-if="loading==true">
      <div class="loader"></div>
    </div>
    <div v-else-if="loading==false" class="wrapper-medal">
      <div class="header-additional">
        <div class="container">
          <div class="header-additional-block">
            <div class="header-additional__logo">
              <img src="images/medal_logo.svg" width="70" height="30">
              <div class="header-additional__desc">
                {{ $t('МедальЕлбасы.заголовок') }}
              </div>
            </div>
            <div class="header-additional__selects">
              <div class="select select--region">
                <el-select
                    @change="changeLang()"
                    :placeholder="$t('Qabilet.ВыберитеЯзык')"
                    v-model="locale"
                    filterable>
                  <el-option
                      key="kz"
                      :label="$t('Qabilet.Казахский')"
                      value="kz">
                  </el-option>
                  <el-option
                      key="ru"
                      :label="$t('Qabilet.Русский')"
                      value="ru">
                  </el-option>
                </el-select>
              </div>

            </div>
          </div>
        </div>
      </div>

      <section class="statistic">
        <div class="container">
          <div class="title">
            {{ $t('МедальЕлбасы.Статистика_в_цифрах') }}
          </div>
          <div class="statistic__rows">
            <div class="statistic__row">
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('МедальЕлбасы.Поданозаявок') }}</div>
                <div class="statistic-card__amount">{{ count_orders  | Numerize }}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('МедальЕлбасы.Наставников') }}</div>
                <div class="statistic-card__amount">{{ 109 | Numerize }}
                  <!--                  mentors-->
                </div>
              </div>
            </div>
            <div class="statistic__row ">
              <div class="statistic-card">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <img src="images/ball.svg">
                  <div class="statistic-card-medal__title">
                    {{ $t('МедальЕлбасы.ПопулярныйВидСпорта') }}
                  </div>
                </div>
                <div class="statistic-card-medal__res">
                  <span v-if="locale=='ru'">{{ sportPopular.title_ru }}</span>
                  <span v-if="locale=='kz'">{{ sportPopular.title_kz }}</span>
                </div>
                <div class="statistic-card-medal__res-amount">
                  {{ sportPopular.count_members | Numerize }}
                </div>
                <div class="statistic-card-medal__desc">
                  {{ $t('МедальЕлбасы.УчастниковПоСпорту') }}
                </div>
              </div>
              <div class="statistic-card">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <img src="images/book.svg">
                  <div class="statistic-card-medal__title">
                    {{ $t('МедальЕлбасы.ВостребованнаяКнига') }}
                  </div>
                </div>
                <div class="statistic-card-medal__res">
                  <span v-if="locale=='ru'">{{ booksPopular.title_ru }}</span>
                  <span v-if="locale=='kz'">{{ booksPopular.title_kz }}</span>
                </div>
                <div class="statistic-card-medal__res-amount">
                  {{ booksPopular.count_members | Numerize }}
                </div>
                <div class="statistic-card-medal__desc">
                  {{ $t('МедальЕлбасы.участников_выбравших_книгу') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="applications">
        <div class="container">
          <div class="title title-applications">
            <div>{{ $t('МедальЕлбасы.Заявки') }}</div>
            <!--            <div class="applications__card-period" style="justify-content: flex-start;margin-left: 2rem">-->
            <!--              <div class="select select&#45;&#45;date-big" style="margin-right: 63px">-->
            <!--                <el-date-picker-->
            <!--                    v-model="medalDateFrom"-->
            <!--                    @change="filterApplication"-->
            <!--                    type="date"-->
            <!--                    placeholder=""-->
            <!--                    format="yyyy-MM-dd"-->
            <!--                    value-format="yyyy-MM-dd"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div class="select select&#45;&#45;date-big" style="margin-right: 63px">-->
            <!--                <el-date-picker-->
            <!--                    v-model="medalDateTo"-->
            <!--                    @change="filterApplication"-->
            <!--                    type="date"-->
            <!--                    placeholder=""-->
            <!--                    format="yyyy-MM-dd"-->
            <!--                    value-format="yyyy-MM-dd"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <button style="width:181px;margin-left: 0" @click="clearWinner()" class="age__clear">-->
            <!--                Сбросить-->
            <!--              </button>-->
            <!--            </div>-->
          </div>

          <div class="applications__row">
            <div class="applications__chart">
              <div v-bind:class="{ 'chart-active': winnersAgeFilter=='is_reserve' }" class="chart"
                   style="cursor: pointer!important" @click=" winnersAgeFilter ='is_reserve'">
                <apexcharts type="donut" height="350" :options="ReservchartOptions"
                            :series="Reservseries"></apexcharts>
              </div>
              <div v-bind:class="{ 'chart-active': winnersAgeFilter=='is_active' }" class="chart"
                   style="cursor: pointer!important" @click=" winnersAgeFilter='is_active'">
                <apexcharts type="donut" height="350" :options="ActivechartOptions"
                            :series="Activeseries"></apexcharts>
              </div>
              <div v-bind:class="{ 'chart-active': winnersAgeFilter=='winners' }" class="chart"
                   style="cursor: pointer!important" @click=" winnersAgeFilter='winners'">
                <apexcharts type="donut" height="350" :options="WinnerchartOptions"
                            :series="Winnerseries"></apexcharts>
              </div>
            </div>

            <div class="applications__card">


              <div class="applications__title-row">
                <div class="applications__title">
                  №
                </div>
                <div class="applications__title">
                  {{ $t('МедальЕлбасы.Регион') }}
                </div>
                <div class="applications__title">
                  {{ $t('МедальЕлбасы.Количество') }}
                </div>
              </div>
              <div class="applications__list">

                <div @click="filterApplication(reg.id,false)" v-for="(reg,index) in  applications" :key="index*2"
                     class="panel panel-default mb-3">
                  <div v-bind:class="{ 'activePanel': regionTogle==reg.id }" class="panel-heading">
                    <div v-bind:class="{ 'activePanel': regionTogle==reg.id }"
                         class="applications__res-row panel-title collapsed" data-toggle="collapse"
                         data-target="#collapseOne">
                      <div v-bind:class="{ 'activePanel': regionTogle==reg.id }" class="applications__num">
                        {{ reg.id }}
                      </div>
                      <div v-bind:class="{ 'activePanel': regionTogle==reg.id }" class="applications__region">
                        <span v-show="locale=='ru'">{{ reg.title_ru }}</span>
                        <span v-show="locale=='kz'">{{ reg.title_kz }}</span>
                      </div>
                      <div v-bind:class="{ 'activePanel': regionTogle==reg.id }" class="applications__amount">
                        <span v-if="winnersAgeFilter=='is_active'">{{ reg.total_members }}</span>
                        <span v-if="winnersAgeFilter=='is_reserve'">{{ reg.total_reserved }}</span>
                        <span v-if="winnersAgeFilter=='winners' ">{{ reg.total_winners }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-show="regionTogle==reg.id" class="panel-collapse">
                    <div class="panel-body">
                      <div class="applications__panel-res panel-collapse">
                        <div class="d-flex panel-collapse" v-if="winnersAgeFilter=='winners' ">
                          <div v-for="(item,index) in reg.ages" :key="index*3"
                               class="d-flex flex-column align-items-center"
                               style="min-width: 52px;margin: 5px;">
                            <div class="mb-3">
                              {{ item.age }} {{ $t('МедальЕлбасы.лет') }}
                            </div>
                            <div>
                              {{ item.count_winners | Numerize }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex" v-if="winnersAgeFilter=='is_reserve'">
                          <div v-for="(item,index) in reg.ages" :key="index*4"
                               class="d-flex flex-column align-items-center"
                               style="min-width: 52px;margin: 5px;">
                            <div class="mb-3">
                              {{ item.age }} {{ $t('МедальЕлбасы.лет') }}
                            </div>
                            <div>
                              {{ item.count_reserved | Numerize }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex" v-if="winnersAgeFilter=='is_active'">
                          <div v-for="(item,index) in reg.ages" :key="index*5"
                               class="d-flex flex-column align-items-center"
                               style="min-width: 52px;margin: 5px;">
                            <div class="mb-3">
                              {{ item.age }} {{ $t('МедальЕлбасы.лет') }}
                            </div>
                            <div>
                              {{ item.count_members | Numerize }}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="audience-mentors">
        <div class="container">
          <div class="d-flex flex-row align-items-center">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a :disabled="loadingFilterTable" class="nav-link" v-bind:class="{ 'active': tabGender==1 }"
                   data-toggle="tab" role="tab"
                   @click="tabGender=1;filterGender()"> {{ $t('МедальЕлбасы.Аудитория') }}</a>
              </li>
              <li class="nav-item">
                <a :disabled="loadingFilterTable" class="nav-link" v-bind:class="{ 'active': tabGender==2 }"
                   data-toggle="tab" role="tab"
                   @click="tabGender=2;filterGender()">{{ $t('МедальЕлбасы.Наставники') }}</a>
              </li>
            </ul>
            <div class="audience-mentors__selects">
              <div v-if="tabGender==1" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.Пол')"
                    v-model="filterTable.genderMember"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'l'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option
                      v-for="(item,index) in genderChartOptions.labels"
                      :key="index*6"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="tabGender==1" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.ВидНаселенногоПункта')"
                    v-model="filterTable.cityType"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'k'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option

                      v-for="(item,index) in membersCityOptions.labels"
                      :key="index*7"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="tabGender==1" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.РодДеятельности')"
                    v-model="filterTable.occupation"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'j'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option

                      v-for="(item,index) in membersOccupationOptions.labels"
                      :key="index*8"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="tabGender==1" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.КатегорияОбразрваеия')"
                    v-model="filterTable.education_category"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'h'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option

                      v-for="(item,index) in membersEducationOptions.labels"
                      :key="index*8"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="tabGender==2" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.Пол')"
                    v-model="filterTable.genderMentor"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'g'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option

                      v-for="(item,index) in genderChartMentorsOptions.labels"
                      :key="index*8"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="tabGender==2" class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.Возраст')"
                    v-model="filterTable.ageMentor"
                    @change="filterGender"
                    filterable value="">
                  <el-option
                      :key="0+'f'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option
                      v-for="(item,index) in ageChartMentorsOptions.labels"
                      :key="index*9"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>

              <div class="select select--region margin-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    :disabled="loadingFilterTable"
                    :placeholder="$t('МедальЕлбасы.Регион')"
                    v-model="regionGender"
                    @change="filterGender(false)"
                    filterable value="">
                  <el-option
                      :key="0+'d'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option
                      v-for="(item,index) in regions"
                      :key="index*10"
                      :label="locale=='ru'?item.title_ru:item.title_kz"
                      :value="item.id">
                  </el-option>

                  <!--                  <el-option-->
                  <!--                      v-for="item in regions"-->
                  <!--                      :key="item.id"-->
                  <!--                      :label="locale=='kz'?item.title_ru:item.title_kz"-->
                  <!--                      :value="item.id">-->

                  <!--                  </el-option>-->
                </el-select>
              </div>
              <div class="select select--date margin-2">
                <el-date-picker
                    :disabled="loadingFilterTable"
                    v-model="GenderDateFrom"
                    @change="filterGender"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date margin-2">
                <el-date-picker
                    :disabled="loadingFilterTable"
                    v-model="GenderDateTo"
                    @change="filterGender"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date margin-2">
                <button
                    :disabled="loadingFilterTable"
                    style="width:181px;margin-left: 0" @click="filterGender(true)" class="age__clear">

                  {{ $t('МедальЕлбасы.Сбросить') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="!loadingFilterTable" class="tab-content">
            <div class="tab-pane active" id="tabs-1" role="tabpanel">
              <div class="audience-mentors__pie-chart">
                <div v-bind:class="{ 'chart-active': curentTable=='gender' }" class="chart "
                     style="cursor: pointer!important" @click="curentTable='gender'"
                     v-show="tabGender==1 && genderChartSeries " id="chart-1">
                  <apexcharts :width="370" :height="370" type="donut" :options="genderChartOptions"
                              :series="genderChartSeries"></apexcharts>
                </div>
                <div v-bind:class="{ 'chart-active': curentTable=='city' }" class="chart"
                     style="cursor: pointer!important" @click="curentTable='city'"
                     v-show="tabGender==1 && membersCitySeries && membersCityOptions" id="chart-2">
                  <apexcharts :width="370" :height="410" type="donut" :options="membersCityOptions"
                              :series="membersCitySeries"></apexcharts>
                </div>
                <div v-bind:class="{ 'chart-active': curentTable=='occupation' }" class="chart"
                     style="cursor: pointer!important" @click="curentTable='occupation'"
                     v-show="tabGender==1 && membersOccupationSeries" id="chart-3">
                  <apexcharts :width="370" :height="370" type="donut" :options="membersOccupationOptions"
                              :series="membersOccupationSeries"></apexcharts>
                </div>
                <div v-bind:class="{ 'chart-active': curentTable=='education'}" class="chart"
                     style="cursor: pointer!important" @click="curentTable='education'"
                     v-show="tabGender==1 && membersEducationSeries" id="chart-4">
                  <apexcharts :width="370" :height="480" type="donut" :options="membersEducationOptions"
                              :series="membersEducationSeries"></apexcharts>
                </div>
                <div v-bind:class="{ 'chart-active': curentTable=='gender' }" class="chart"
                     style="cursor: pointer!important" @click="curentTable='gender'"
                     v-show="tabGender==2 && genderChartMentorsSeries" id="chart-5">
                  <apexcharts :width="370" :height="370" type="donut" :options="genderChartMentorsOptions"
                              :series="genderChartMentorsSeries"></apexcharts>
                </div>
                <div v-show="tabGender==2  && mentorsAgeSeries && ageChartMentorsOptions"
                     v-bind:class="{ 'chart-active': curentTable=='age' }" class="chart"
                     style="cursor: pointer!important" @click="curentTable='age'"
                     id="chart-6">
                  <apexcharts :width="370" :height="370" type="donut" :options="ageChartMentorsOptions"
                              :series="mentorsAgeSeries"></apexcharts>
                </div>
              </div>
              <div class="mb-rating__table-block mb-rating__table-block--schools">
                <table v-show="tabGender==1 &&  curentTable=='city' &&  cityTable" class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title"> {{ $t('МедальЕлбасы.Названиеобласти') }}</th>
                    <th v-for="(item,index) in cityTable[0].types" scope="col" class="audience-mentors-table__title"
                        :key="index*11">
                      <span v-show="locale=='ru'">{{ item.title_ru }}</span>
                      <span v-show="locale=='kz'">{{ item.title_kz }}</span>
                    </th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in cityTable" :key="index*12">
                    <th scope="row">{{ item.id }}</th>
                    <td v-if="locale=='ru'">{{ item.title_ru }}</td>
                    <td v-if="locale=='kz'">{{ item.title_kz }}</td>
                    <td v-for="(item,index) in cityTable[index].types" class="text-center" :key="index*13">{{
                        item.count_members | Numerize
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td v-for="(item,index) in cityTableAll" :key="index*14" class="text-center">
                      {{ item.count_members | Numerize }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table v-if="tabGender==1 &&  curentTable=='occupation' && occupationTable"
                       class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Названиеобласти') }}</th>
                    <th v-for="(item,index) in occupationTable[0].occupations" scope="col"
                        class="audience-mentors-table__title"
                        :key="index*15"><span v-show="locale=='ru'">{{ item.title_ru }}</span><span
                        v-show="locale=='kz'">{{ item.title_kz }}</span>
                    </th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in occupationTable" :key="index*16">
                    <th scope="row">{{ item.id }}</th>
                    <td><span v-show="locale=='ru'">{{ item.title_ru }}</span><span
                        v-show="locale=='kz'">{{ item.title_kz }}</span></td>
                    <td v-for="(item,index) in occupationTable[index].occupations" class="text-center" :key="index*17">
                      {{ item.count_members | Numerize }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td v-for="(item,index) in occupationTableAll" :key="index*18" class="text-center">
                      {{ item.count_members | Numerize }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table v-if="tabGender==1 &&  curentTable=='education' && educationTable"
                       class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Названиеобласти') }}</th>
                    <th v-for="(item,index) in educationTable[0].categories" scope="col"
                        class="audience-mentors-table__title" :key="index*19">{{
                        membersEducationOptions.labels[index]
                      }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in educationTable" :key="index*20">
                    <th scope="row">{{ index + 1 }}</th>
                    <td><span v-show="locale=='ru'">{{ item.title_ru }}</span><span
                        v-show="locale=='kz'">{{ item.title_kz }}</span></td>
                    <td v-for="(item,index) in educationTable[index].categories" class="text-center" :key="index*21">
                      {{ item.count_members | Numerize }}
                    </td>

                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td v-for="(item,index) in educationTableAll" :key="index*22" class="text-center">
                      {{ item.count_members | Numerize }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table v-if="tabGender==1 &&  curentTable=='gender' && membersGenderArea "
                       class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Регион') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.мужской') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.женский') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Количество') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in membersGenderArea" :key="index*23">
                    <th scope="row">{{ item.id }}</th>
                    <td><span v-show="locale=='ru'">{{ item.title_ru }}</span><span
                        v-show="locale=='kz'">{{ item.title_kz }}</span></td>
                    <td class="text-center">{{ item.count_male | Numerize }}</td>
                    <td class="text-center">{{ item.count_female | Numerize }}</td>
                    <td class="text-center">{{ item.count_male + item.count_female | Numerize }}</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td class="text-center">{{ membersGender.count_male | Numerize }}</td>
                    <td class="text-center">{{ membersGender.count_female | Numerize }}</td>
                    <td class="text-center">{{ membersGender.count_male + membersGender.count_female | Numerize }}</td>
                  </tr>
                  </tbody>
                </table>
                <table v-if="tabGender==2 &&  curentTable=='gender' && mentorsGenderArea "
                       class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Регион') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.мужской') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.женский') }}</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Количество') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in mentorsGenderArea" :key="index*24">
                    <th scope="row">{{ item.id }}</th>
                    <td><span v-show="locale=='ru'">{{ item.title_ru }}</span><span
                        v-show="locale=='kz'">{{ item.title_kz }}</span></td>
                    <td class="text-center">{{ item.count_male | Numerize }}</td>
                    <td class="text-center">{{ item.count_female | Numerize }}</td>
                    <td class="text-center">{{ item.count_male + item.count_female | Numerize }}</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td class="text-center">{{ mentorsGender.count_male | Numerize }}</td>
                    <td class="text-center">{{ mentorsGender.count_female | Numerize }}</td>
                    <td class="text-center">{{ mentorsGender.count_male + mentorsGender.count_female | Numerize }}</td>
                  </tr>
                  </tbody>
                </table>
                <table v-if="tabGender==2 &&  curentTable=='age' && ageTable " class="table audience-mentors-table">
                  <thead>
                  <tr>
                    <th scope="col" class="audience-mentors-table__title">№</th>
                    <th scope="col" class="audience-mentors-table__title">{{ $t('МедальЕлбасы.Возраст') }}</th>
                    <th v-for="(item,index) in ageTable[0].ages" scope="col" class="audience-mentors-table__title"
                        :key="index*25">
                      {{ item.age }}
                    </th>


                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in ageTable" :key="index*26">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <span v-show="locale=='ru'">
                        {{ item.title_ru }}
                      </span>
                      <span v-show="locale=='kz'">
                        {{ item.title_kz }}
                      </span></td>
                    <td v-for="(item,index) in ageTable[index].ages" class="text-center" :key="index*27">{{
                        item.count_mentors | Numerize
                      }}
                    </td>


                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>{{ $t('Qabilet.итого') }}</td>
                    <td v-for="(item,index) in ageTableAll.ages" :key="index*28" class="text-center">
                      {{ item.count_mentors | Numerize }}
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="tabs-2" role="tabpanel">
              <div class="audience-mentors__pie-chart">

              </div>
            </div>
          </div>
          <div style="display:flex;justify-content: center;margin-top: 3rem" v-show="loadingFilterTable">
            <div class="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>

        </div>
      </section>

      <section class="age">
        <div class="container">
          <div class="d-flex flex-row align-items-center">
            <div class="title">
              {{ $t('МедальЕлбасы.Возраст') }} {{ $t('весьПериод') }}
            </div>
            <div class="age__selects">
              <div class="select select--region mr-2">
                <el-select
                    :disabled="chartLoad"
                    v-model="currenLocaleAgeChart"
                    :placeholder="$t('МедальЕлбасы.Регион')"
                    @change="ageChartFilter"
                    filterable value="">
                  <el-option
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      value="0">
                  </el-option>
                  <el-option
                      v-for="(item,index) in regions"
                      :key="index*29"
                      :label="locale=='ru'?item.title_ru:item.title_kz"
                      :value="item.id">
                  </el-option>

                </el-select>
              </div>
              <!--              <div class="select select&#45;&#45;region mr-2">-->
              <!--                <el-select-->
              <!--                    :disabled="chartLoad"-->
              <!--                    v-model="regionAgeFilterRole"-->
              <!--                    placeholder="Роль"-->
              <!--                    filterable value="">-->
              <!--                  <el-option-->
              <!--                      :label="$t('МедальЕлбасы.ПоказатьВсе')"-->
              <!--                      value="0">-->
              <!--                  </el-option>-->
              <!--                  <el-option-->
              <!--                      :label="$t('МедальЕлбасы.Резерв')"-->
              <!--                      value="1">-->
              <!--                  </el-option>-->
              <!--                  <el-option-->
              <!--                      :label="$t('МедальЕлбасы.Активныеучастники')"-->
              <!--                      value="2">-->
              <!--                  </el-option>-->
              <!--                  <el-option-->
              <!--                      :label="$t('МедальЕлбасы.Победители')"-->
              <!--                      value="3">-->
              <!--                  </el-option>-->
              <!--                  <el-option-->
              <!--                      :label="$t('МедальЕлбасы.Отклонен')"-->
              <!--                      value="4">-->
              <!--                  </el-option>-->

              <!--                </el-select>-->
              <!--              </div>-->
            </div>
            <button :disabled="chartLoad" @click="currenLocaleAgeChart='0';regionAgeFilterRole='0';ageChartFilter()"
                    class="age__clear">
              {{ $t('МедальЕлбасы.Сбросить') }}
            </button>
          </div>
          <div class="age_diagramm">
            <div v-if="!chartOptionsAgeLoad" ref="AgeChart">
              <apexcharts type="bar" height="450" :options="chartOptionsAge" :series="seriesAge"></apexcharts>
            </div>
          </div>
        </div>
      </section>

      <section class="tasks">
        <div class="container">
          <div class="d-flex flex-row align-items-center">
            <div class="title">
              {{ $t('МедальЕлбасы.Задания') }}
            </div>
            <div class="age__selects">
              <div class="select select--region mr-2">
                <input class="select__input" type="hidden" name="">
                <el-select
                    v-model="filterTaskReg"
                    :placeholder="$t('МедальЕлбасы.Регион')"
                    @change="filterTask"
                    filterable value="">
                  <el-option
                      :key="0+'s'"
                      :label="$t('МедальЕлбасы.ПоказатьВсе')"
                      :value="0">
                  </el-option>
                  <el-option
                      :label="locale=='ru'?item.title_ru:item.title_kz"
                      v-for="(item,index) in regions"
                      :key="index*30"
                      :value="item.id">
                  </el-option>
                </el-select>

              </div>
              <div class="select select--date mr-2">
                <el-date-picker
                    v-model="TaskDateFrom"
                    @change="filterTask"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date mr-2">
                <el-date-picker
                    v-model="TaskDateTo"
                    @change="filterTask"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date ">
                <button style="width:181px;margin-left: 0" @click="filterTask(0,true)" class="age__clear">
                  {{ $t('МедальЕлбасы.Сбросить') }}
                </button>
              </div>
            </div>
          </div>
          <div class="tasks__rows">
            <div class="tasks__row">
              <div class="tasks-card tasks-card--green">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.ВсегоЗаданий') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.allCount | Numerize }}</div>

              </div>
              <div class="tasks-card tasks-card--dark">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.НеНачато') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_new | Numerize }}</div>
              </div>
              <div class="tasks-card">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.НеВыполнено') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_not_performed | Numerize }}</div>
              </div>
              <div class="tasks-card tasks-card--dark">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.Невыполненовсрок') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_waited | Numerize }}</div>
              </div>
              <div class="tasks-card">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.ФотоКонтент') }}</div>
                <div class="tasks-card__amount">{{ fotoContent | Numerize }}</div>
              </div>
              <div class="tasks-card">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.НаПодтверждении') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_on_confirmation | Numerize }}</div>
              </div>
              <div class="tasks-card tasks-card--red">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.ИстекСрокПроверки') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_expired_checking | Numerize }}</div>
              </div>
              <div class="tasks-card tasks-card--dark">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.отклонено') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_refused | Numerize }}</div>
              </div>
              <div class="tasks-card">
                <div class="tasks-card__title"> {{ $t('МедальЕлбасы.Подтверждено') }}</div>
                <div class="tasks-card__amount">{{ tasksDirections.count_confirmed | Numerize }}</div>
              </div>
              <div class="tasks-card tasks-card--dark">
                <div class="tasks-card__title">{{ $t('МедальЕлбасы.видеоКонтент') }}</div>
                <div class="tasks-card__amount">{{ videoContent | Numerize }}</div>
              </div>
            </div>
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table class="table tasks-table">
              <thead>
              <tr>
                <th class="tasks-table__title">№</th>
                <th class="tasks-table__title">{{ $t('МедальЕлбасы.ФиоНаставника') }}

                  <div @click="mentorsDirection('sort_name')" class="table-buttons-wrapper">
                    <button class="table-buttons-up"></button>
                    <button class="table-buttons-down"></button>

                  </div>
                </th>
                <th class="tasks-table__title">{{ $t('МедальЕлбасы.Регион') }}
                  <div @click="mentorsDirection('sort_area')" class="table-buttons-wrapper">
                    <button class="table-buttons-up"></button>
                    <button class="table-buttons-down"></button>

                  </div>
                </th>
                <th class="tasks-table__title">{{ $t('МедальЕлбасы.НаселенныйПункт') }}
                </th>
                <th class="tasks-table__title">KPI

                </th>
                <th class="tasks-table__title">{{ $t('МедальЕлбасы.Количествопросроченныхпроверок') }}

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in mentorsArea" :key="index*31">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>

                <td v-if="locale=='ru'">{{ item.area.title_ru }}</td>
                <td v-if="locale=='ru'">{{ item.city.title_ru }}</td>
                <td v-if="locale=='kz'">{{ item.area.title_kz }}</td>
                <td v-if="locale=='kz'">{{ item.city.title_kz }}</td>
                <td>{{ item.kpi_count | Numerize }}</td>
                <td>{{ item.expiring_checkings }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <nav class="tasks__pagination">

            <el-pagination
                :disabled="loadingStats"
                :pager-count="11"
                :current-page='statsCurrentPage'
                @current-change="statsFilter"
                layout="prev, pager, next"
                :total="statsMeta.last_page*10">
            </el-pagination>

            <!--            <ul class="pagination">-->
            <!--              <li class="page-item">-->
            <!--                <a class="page-link" @click="statsFilter(kpiCurrentPage-1)" aria-label="Previous">-->
            <!--                                <span aria-hidden="true">-->
            <!--                                    <img src="images/arrow-prev.svg">-->
            <!--                                </span>-->
            <!--                </a>-->
            <!--              </li>-->
            <!--              <li v-for="item in statsMeta.last_page" class="page-item" :key="item"><a class="page-link"-->
            <!--                                                                                       v-bind:class="{ 'active': item==statsCurrentPage }"-->


            <!--                                                                                       @click="statsFilter(item)">{{-->
            <!--                  item-->
            <!--                }}</a>-->
            <!--              </li>-->

            <!--              <li class="page-item">-->
            <!--                <a class="page-link" @click="statsFilter(kpiCurrentPage+1)" aria-label="Next">-->
            <!--                                <span aria-hidden="true">-->
            <!--                                    <img src="images/arrow-next.svg">-->
            <!--                                </span>-->
            <!--                </a>-->
            <!--              </li>-->
            <!--            </ul>-->
          </nav>
        </div>
      </section>

      <section class="special-tasks">
        <div class="container">
          <div class="d-flex flex-row align-items-center">
            <div class="title">
              {{ $t('МедальЕлбасы.ЗаданияПоНаправлениям') }}
            </div>
            <!--            <div class="age__selects">-->
            <!--              <div class="select select&#45;&#45;region mr-2">-->
            <!--                <input class="select__input" type="hidden" name="">-->
            <!--                <el-select-->
            <!--                    v-model="DirectionReg"-->
            <!--                    :placeholder="$t('МедальЕлбасы.Регион')"-->
            <!--                    @change="filterDirection(false)"-->
            <!--                    filterable value="">-->
            <!--                  <el-option-->
            <!--                      :key="0+'a'"-->
            <!--                      :label="$t('МедальЕлбасы.ПоказатьВсе')"-->
            <!--                      :value="0">-->
            <!--                  </el-option>-->
            <!--                  <el-option-->
            <!--                      v-show="locale=='ru'"-->
            <!--                      v-for="(item,index) in regions"-->
            <!--                      :key="index*32"-->
            <!--                      :label="locale=='ru'?item.title_ru:item.title_kz"-->
            <!--                      :value="item.id">-->
            <!--                  </el-option>-->

            <!--                </el-select>-->
            <!--              </div>-->
            <!--              <div class="select select&#45;&#45;date mr-2">-->
            <!--                <el-select-->
            <!--                    class="select&#45;&#45;date"-->
            <!--                    @change="filterDirection"-->
            <!--                    v-model="DirectionDateFrom"-->
            <!--                    filterable-->
            <!--                    placeholder="Год от"-->
            <!--                >-->
            <!--                  <el-option-->
            <!--                      v-for="(item,index) in years"-->
            <!--                      :key="index*33"-->
            <!--                      :label="item.name"-->
            <!--                      :value="item.id">-->
            <!--                  </el-option>-->
            <!--                </el-select>-->
            <!--              </div>-->
            <!--              <div class="select select&#45;&#45;date mr-2 ">-->
            <!--                <el-select-->
            <!--                    class="select&#45;&#45;date"-->
            <!--                    @change="filterDirection"-->
            <!--                    v-model="DirectionDateTo"-->
            <!--                    filterable-->
            <!--                    placeholder="Год до"-->
            <!--                >-->
            <!--                  <el-option-->
            <!--                      v-for="(item,index) in years"-->
            <!--                      :key="index*34"-->
            <!--                      :label="item.name"-->
            <!--                      :value="item.id">-->
            <!--                  </el-option>-->
            <!--                </el-select>-->

            <!--              </div>-->
            <!--              <div class="select select&#45;&#45;date">-->
            <!--                <button style="width:181px;margin-left: 0" @click="filterDirection(true)" class="age__clear">-->
            <!--                  {{$t('МедальЕлбасы.Сбросить')}}-->
            <!--                </button>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="tasks__rows">
            <div data-v-0881fbbc="" class="tasks__row" style="flex-wrap: wrap;">
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span
                    v-if="$i18n.locale=='ru'"
                    data-v-0881fbbc="">Спорт и физическое развитие</span><span data-v-0881fbbc=""
                                                                               v-else>Спорт және дене шынықтыру</span>
                </div>
                <div data-v-0881fbbc="" class="tasks-card__amount">254 299</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span v-if="$i18n.locale=='ru'" data-v-0881fbbc="">Таланты и навыки</span><span
                    data-v-0881fbbc="" v-else>Дарын мен дағды</span></div>
                <div data-v-0881fbbc="" class="tasks-card__amount">241 145</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span data-v-0881fbbc="" v-if="$i18n.locale=='ru'">Чтение книг</span><span
                    data-v-0881fbbc="" v-else>Кітап оқу</span></div>
                <div data-v-0881fbbc="" class="tasks-card__amount">243 881</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span v-if="$i18n.locale=='ru'"
                                                                        data-v-0881fbbc="">Национальное наследие</span><span
                    data-v-0881fbbc="" v-else>Ұлттық мұра</span>
                </div>
                <div data-v-0881fbbc="" class="tasks-card__amount">173 933</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span data-v-0881fbbc="" v-if="$i18n.locale=='ru'">Волонтерство</span><span
                    data-v-0881fbbc="" v-else>Волонтерлік іс</span></div>
                <div data-v-0881fbbc="" class="tasks-card__amount">200 083</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title"><span v-if="$i18n.locale=='ru'"
                                                                        data-v-0881fbbc="">Туристические походы</span><span
                    v-else data-v-0881fbbc="">Туристік жорықтар</span>
                </div>
                <div data-v-0881fbbc="" class="tasks-card__amount">150</div>
              </div>
              <div data-v-0881fbbc="" class="tasks-card">
                <div data-v-0881fbbc="" class="tasks-card__title">
                  <span data-v-0881fbbc="" v-if="$i18n.locale=='ru'">Трудовая практика</span>
                  <span v-else data-v-0881fbbc="">Еңбек тәжірибесі</span></div>
                <div data-v-0881fbbc="" class="tasks-card__amount">144</div>
              </div>
            </div>
            <!--            <div class="tasks__row" style="flex-wrap: wrap">-->
            <!--              <div @click="showModal(direction.id)" v-for="(direction,index) in directions"   :key="index*35"-->
            <!--                   class="tasks-card">-->
            <!--                <div class="tasks-card__title"><span  v-show="locale=='ru'">{{ direction.title_ru  }}</span><span  v-show="locale=='kz'">{{direction.title_kz  }}</span></div>-->
            <!--                <div class="tasks-card__amount">{{ direction.allCount | Numerize}}</div>-->
            <!--              </div>-->
            <!--              -->
            <!--            </div>-->
          </div>
        </div>
      </section>
      <b-modal size="xl" id="modal-item" hide-footer hide-header>
        <button class="btn-close-top" @click="$bvModal.hide('modal-item')">
          <img width="44" src="/images/close.svg" alt="">
        </button>
        <table style="margin-top: 44px;" class="table kpi-table table-striped">
          <thead>
          <tr>
            <th scope="col">Категория</th>
            <th style="width:10%" scope="col">{{ $t('МедальЕлбасы.Количество') }}</th>
            <th scope="col">Подкатегория</th>
            <th style="width:10%" scope="col">{{ $t('МедальЕлбасы.Количество') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr style="font-size: 18px!important;line-height: 20px!important"
              v-for="(item,index) in directions[directionsId].categories" :key="index*36">
            <th style="font-size: 18px!important;line-height: 20px!important" scope="row"><span
                v-show="locale=='ru'">{{ item.title_ru }}</span><span v-show="locale=='kz'">{{ item.title_kz }}</span>
            </th>
            <td style="font-size: 18px!important;line-height: 20px!important">
              {{
                item.tasks.count_confirmed + item.tasks.count_expired_checking + item.tasks.count_moved + item.tasks.count_new + item.tasks.count_not_performed + item.tasks.count_on_confirmation + item.tasks.count_on_moving + item.tasks.count_refused + item.tasks.count_waited
                    | Numerize
              }}
            </td>
            <td style="font-size: 18px!important;line-height: 20px!important">
              <span
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width:500px;display: block"
                  v-for="(cat,index) in item.subcategories" :key="index*37">
                <span v-show="locale=='ru'">{{ cat.title_ru }}</span><span v-show="locale=='kz'">{{
                  cat.title_kz
                }}</span>

                <br></span>

            </td>
            <td style="font-size: 18px!important;line-height: 20px!important"><span
                v-for="(catCount,index) in item.subcategories"
                :key="index*38">{{
                catCount.tasks.count_confirmed + catCount.tasks.count_expired_checking + catCount.tasks.count_moved + catCount.tasks.count_new + catCount.tasks.count_not_performed + catCount.tasks.count_on_confirmation + catCount.tasks.count_on_moving + catCount.tasks.count_refused + catCount.tasks.count_waited
                    | Numerize
              }} <br></span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-modal>
      <section class="kpi">
        <div class="container">
          <div class="title">
            {{ $t('МедальЕлбасы.KPIПрогресс') }} {{ $t('весьПериод') }}
          </div>
          <div class="kpi__row">
            <div class="kpi__table-block">
              <div class="kpi__header">
                <div class="d-flex flex-row align-items-center">
                  <div class="kpi__search search">
                    <input v-on:keyup.enter="kpiSearch()" v-model="kpiFio" class="form-control search__input"
                           type="search" :placeholder="$t('МедальЕлбасы.ПоискФио')"
                           aria-label="Search">
                    <button @click="kpiSearch()" class="search__btn" type="submit">
                      <img src="images/search.svg">
                    </button>
                  </div>
                </div>
                <nav class="kpi__pagination">
                  <el-pagination
                      :disabled="kpiLoading"
                      :current-page='kpiCurrentPage'
                      @current-change="kpiSearch"
                      layout="prev, pager, next"
                      :total="kpiMeta.last_page*10">
                  </el-pagination>

                </nav>


              </div>
              <div class="kpi__table">
                <table class="table kpi-table table-striped">
                  <thead>
                  <tr>
                    <th scope="col" class="kpi-table__title">№</th>
                    <th scope="col" class="kpi-table__title">{{ $t('МедальЕлбасы.ФИО') }}</th>
                    <th scope="col" class="kpi-table__title">KPI</th>
                    <th scope="col" class="kpi-table__title">{{ $t('МедальЕлбасы.Прогресс') }}</th>
                    <th scope="col" class="kpi-table__title">{{ $t('МедальЕлбасы.Регион') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="cursor: pointer!important;max-height:64px;min-height: 64px" @click="kpiSearchItem(index+1)"
                      v-for="(kpiItem,index) in kpi"
                      :key="index*39">
                    <th v-bind:class="{'activeUser':currentUserKpi==index + 1 }" scope="row">{{ index + 1 }}</th>
                    <td style="width: 30%;" v-bind:class="{'activeUser':currentUserKpi==index + 1 }">{{
                        kpiItem.name
                      }}
                    </td>
                    <td v-bind:class="{'activeUser':currentUserKpi==index + 1 }">{{ kpiItem.current_kpi }}%</td>
                    <td v-bind:class="{'activeUser':currentUserKpi==index + 1 }">{{ kpiItem.current_progress }}%</td>
                    <td v-bind:class="{'activeUser':currentUserKpi==index + 1 }"><span
                        v-if="locale=='ru'">{{ kpiItem.area.title_ru }}</span><span
                        v-if="locale=='kz'">{{ kpiItem.area.title_kz }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-show="kpi.length>0 && chartOptionsKpi.xaxis.categories.length>0  "
                 class="chartKpi">
              <div class="chartKpiTitle">KPI</div>
              <div class="chartKpiEl" v-bind:style="{'height':currentKpi+'%'}">
                <div class="chartKpiText">{{ currentKpi }}%</div>
              </div>
            </div>
            <div v-if="chartOptionsKpi.xaxis.categories.length>0" class="kpi__diagramm">

              <div>
                <apexcharts type="radar" height="614" :options="chartOptionsKpi" :series="seriesKpi"></apexcharts>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MedalElbasy',
  components: {
    apexcharts: VueApexCharts,
  },

  data() {
    return {
      count_orders: 0,
      loadingFilterTable: false,
      loadingFilterTable1: false,
      loadingFilterTable2: false,
      loadingFilterTable2_1: false,
      loadingFilterTable2_2: false,
      loadingFilterTable3: false,
      loadingFilterTable4: false,
      paginateOpt: {
        chunk: 10,
        texts: {count: ''}
      },
      currenLocaleAgeChart: '',
      regionAgeFilterRole: '0',
      chartload: false,
      winnersAgeFilter: 'winners',
      curentTable: 'gender',
      DirectionReg: '',
      kpiLoading: false,
      DirectionDateFrom: '',
      DirectionDateTo: '',
      filterTable: {
        genderMember: '',
        cityType: '',
        occupation: '',
        education_category: '',
        genderMentor: '',
        ageMentor: '',
      },
      medalDateFrom: '',
      medalDateTo: '',
      GenderDateFrom: '',
      GenderDateTo: '',
      TaskDateFrom: '',
      TaskDateTo: '',
      filterTaskReg: '',
      regionGender: '',
      currentUserKpi: 1,
      currentKpi: false,
      seriesKpi: [],
      chartOptionsKpi: {
        yaxis: {tickAmount: 4, max: 100, min: 0,},
        colors: ['#277675', '#009695', '#09B1B0', '#68E7E6'],
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          height: 350,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.1
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          }
        }
      },
      Winnerseries: [],
      WinnerchartOptions: {
        colors: ['#FFD700', '#C0C0C0', '#CD7F32'],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: this.$t('МедальЕлбасы.Победители'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },

        labels: [this.$t('МедальЕлбасы.Золото'), this.$t('МедальЕлбасы.Серебро'), this.$t('МедальЕлбасы.Бронза')],
      },


      Activeseries: [],
      ActivechartOptions: {
        colors: ['#FFD700', '#C0C0C0', '#CD7F32'],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: this.$t('МедальЕлбасы.Активныеучастники'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: [this.$t('МедальЕлбасы.Золото'), this.$t('МедальЕлбасы.Серебро'), this.$t('МедальЕлбасы.Бронза')],
      },


      Reservseries: [],
      ReservchartOptions: {
        colors: ['#FFD700', '#C0C0C0', '#CD7F32'],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: this.$t('МедальЕлбасы.Резерв'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: [this.$t('МедальЕлбасы.Золото'), this.$t('МедальЕлбасы.Серебро'), this.$t('МедальЕлбасы.Бронза')],
      },


      seriesAge: [],
      chartOptionsAgeLoad: false,
      chartOptionsAge: {
        legend: {
          fontSize: '14px',
          position: 'top',
          horizontalAlign: 'left',
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#277675', '#009695', '#09B1B0', '#68E7E6'],
        chart: {

          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: 'text',
          categories: []
        },


        fill: {
          opacity: 1
        }
      },


      tasksDirections: {
        count_new: 0,
        count_expired_checking: 0,
        count_on_confirmation: 0,
        count_refused: 0,
        allCount: 0,
        count_confirmed: 0,
        count_not_performed: 0,
        count_waited: 0,
      },
      membersEducationSeries: [],
      membersEducationOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#68E7E6', '#277675', '#42A4A3', '#4FBAB9', '#5CD1D0'],
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 380,
              height: 380
            },

          }
        }],
        legend: {
          horizontalAlign: 'left',
          show: true,
          position: 'bottom',

        },
        title: {
          text: 'Категория образования',
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },


      membersOccupationSeries: [],
      membersOccupationOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#68E7E6', '#277675', '#42A4A3', '#4FBAB9', '#5CD1D0'],
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200
            },

          }
        }],
        legend: {
          show: true,
          position: 'bottom',

        },
        title: {
          text: this.$t('МедальЕлбасы.РодДеятельности'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },


      membersCitySeries: [],
      membersCityOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#68E7E6', '#277675', '#42A4A3', '#4FBAB9', '#5CD1D0'],
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200
            },

          }
        }],
        legend: {
          show: true,
          position: 'bottom',

        },
        title: {
          text: this.$t('МедальЕлбасы.ВидНаселенногоПункта'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },
      ageChartMentorsOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#68E7E6', '#277675', '#42A4A3', '#4FBAB9', '#5CD1D0'],
        labels: ['20-30', '30-40', '50-60', '60-70'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200
            },

          }
        }],
        legend: {
          show: true,
          position: 'bottom',

        },
        title: {
          text: this.$t('МедальЕлбасы.Возраст'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          },
        },
      },
      genderChartMentorsOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#09B1B0', '#68E7E6'],
        labels: [this.$t('МедальЕлбасы.мужской'), this.$t('МедальЕлбасы.женский')],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200
            },

          }
        }],
        legend: {
          show: true,
          position: 'bottom',

        },
        title: {
          text: this.$t('МедальЕлбасы.Пол'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },
      genderChartMentorsSeries: [],
      genderChartSeries: [],
      genderChartOptions: {
        chart: {
          type: 'donut',
        },
        colors: ['#09B1B0', '#68E7E6'],
        labels: [this.$t('МедальЕлбасы.мужской'), this.$t('МедальЕлбасы.женский')],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200
            },

          }
        }],
        legend: {
          show: true,
          position: 'bottom',

        },
        title: {
          text: this.$t('МедальЕлбасы.Пол'),
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
      },
      directionsId: 0,
      regionTogle: '',
      regionAgeFilter: '',
      mentorsAgeSeries: [0, 0, 0, 0, 0],
      locale: this.$i18n.locale,
      statsCurrentPage: 1,
      statsMeta: [],
      kpiCurrentPage: 1,
      kpiMeta: [],
      kpiFio: "",
      mentorsGender: {
        count_male: 0,
        count_female: 0
      },
      membersGender: {
        count_male: 0,
        count_female: 0
      },
      fotoContent: 0,
      videoContent: 0,
      tabGender: 1,
      mentorsGenderArea: [],
      membersGenderArea: [],
      regions: [],
      mentorsArea: [],
      directions: [{categories: ''}],
      kpi: [],
      applications: {},
      applicationsToggle: [],
      mentors: 0,
      membersCount: 0,
      members: {},
      booksPopular: {},
      sportPopular: {},
      agesCharColumns: [],
      cityTable: [{types: []}],
      cityTableAll: {},
      occupationTable: {},
      occupationTableAll: {},
      educationTable: [{categories: ''}],
      educationTableAll: {},
      ageTable: {},
      ageTableAll: {},
      mentorsDirectionType: {
        sort_name: 'asc',
        sort_area: 'asc',
      },
      loadingStats: false,
      loading: true,
      loadingApi: {
        membersAge: true,
        members: true,
        books: true,
        sport: true,
        membersAgeDate: true,
        kpi: true,
        directions: true,
        mentors: true,
        areas: true,
        membersGender: true,
        mentorsGender: true,
        tasksType: true,
        mentorsAgeSeries: true,
        membersProgram: true,
        membersDirections: true,
      },
      years: [],
      paramsBody: {
        "total_count": 9145, "gender": {
          "total": {"count_male": 2388, "procent_male": 26.11, "count_female": 6757, "procent_female": 73.89},
          "regions": [{
            "id": 1,
            "title_ru": "\u0410\u043a\u043c\u043e\u043b\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u049b\u043c\u043e\u043b\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 104,
            "procent_male": 1.14,
            "count_female": 332,
            "procent_female": 3.63
          }, {
            "id": 2,
            "title_ru": "\u0410\u043a\u0442\u044e\u0431\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u049b\u0442\u04e9\u0431\u0435 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 89,
            "procent_male": 0.97,
            "count_female": 356,
            "procent_female": 3.89
          }, {
            "id": 3,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 258,
            "procent_male": 2.82,
            "count_female": 590,
            "procent_female": 6.45
          }, {
            "id": 4,
            "title_ru": "\u0410\u0442\u044b\u0440\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u0442\u044b\u0440\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 177,
            "procent_male": 1.94,
            "count_female": 309,
            "procent_female": 3.38
          }, {
            "id": 5,
            "title_ru": "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0428\u044b\u0493\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 141,
            "procent_male": 1.54,
            "count_female": 600,
            "procent_female": 6.56
          }, {
            "id": 6,
            "title_ru": "\u0416\u0430\u043c\u0431\u044b\u043b\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0416\u0430\u043c\u0431\u044b\u043b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 89,
            "procent_male": 0.97,
            "count_female": 297,
            "procent_female": 3.25
          }, {
            "id": 7,
            "title_ru": "\u0417\u0430\u043f\u0430\u0434\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0411\u0430\u0442\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 178,
            "procent_male": 1.95,
            "count_female": 352,
            "procent_female": 3.85
          }, {
            "id": 8,
            "title_ru": "\u041a\u0430\u0440\u0430\u0433\u0430\u043d\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u049a\u0430\u0440\u0430\u0493\u0430\u043d\u0434\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 30,
            "procent_male": 0.33,
            "count_female": 102,
            "procent_female": 1.12
          }, {
            "id": 9,
            "title_ru": "\u041a\u043e\u0441\u0442\u0430\u043d\u0430\u0439\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u043e\u0441\u0442\u0430\u043d\u0430\u0439 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 152,
            "procent_male": 1.66,
            "count_female": 363,
            "procent_female": 3.97
          }, {
            "id": 10,
            "title_ru": "\u041a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 38,
            "procent_male": 0.42,
            "count_female": 177,
            "procent_female": 1.94
          }, {
            "id": 11,
            "title_ru": "\u041c\u0430\u043d\u0433\u0438\u0441\u0442\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u041c\u0430\u04a3\u0493\u044b\u0441\u0442\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 148,
            "procent_male": 1.62,
            "count_female": 323,
            "procent_female": 3.53
          }, {
            "id": 12,
            "title_ru": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 172,
            "procent_male": 1.88,
            "count_female": 368,
            "procent_female": 4.02
          }, {
            "id": 13,
            "title_ru": "\u0421\u0435\u0432\u0435\u0440\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0421\u043e\u043b\u0442\u04af\u0441\u0442\u0456\u043a \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 98,
            "procent_male": 1.07,
            "count_female": 276,
            "procent_female": 3.02
          }, {
            "id": 14,
            "title_ru": "\u0422\u0443\u0440\u043a\u0435\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0422\u04af\u0440\u043a\u0456\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "count_male": 79,
            "procent_male": 0.86,
            "count_female": 446,
            "procent_female": 4.88
          }, {
            "id": 16,
            "title_ru": "\u041d\u0443\u0440-\u0421\u0443\u043b\u0442\u0430\u043d",
            "title_kz": "\u041d\u04b1\u0440-\u0421\u04b1\u043b\u0442\u0430\u043d",
            "count_male": 201,
            "procent_male": 2.2,
            "count_female": 512,
            "procent_female": 5.6
          }, {
            "id": 17,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "count_male": 239,
            "procent_male": 2.61,
            "count_female": 758,
            "procent_female": 8.29
          }, {
            "id": 18,
            "title_ru": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "title_kz": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "count_male": 195,
            "procent_male": 2.13,
            "count_female": 596,
            "procent_female": 6.52
          }]
        },
        "city_types": {
          "total": [{
            "id": 1,
            "title_ru": "\u0413\u043e\u0440\u043e\u0434",
            "title_kz": "\u049a\u0430\u043b\u0430",
            "count_members": 6925,
            "procent_members": 74.99
          }, {
            "id": 2,
            "title_ru": "\u0421\u0435\u043b\u043e",
            "title_kz": "\u0421\u0435\u043b\u043e",
            "count_members": 1374,
            "procent_members": 14.88
          }, {
            "id": 3,
            "title_ru": "\u0410\u0443\u043b",
            "title_kz": "\u0410\u0443\u044b\u043b",
            "count_members": 530,
            "procent_members": 5.74
          }, {
            "id": 4,
            "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
            "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
            "count_members": 1,
            "procent_members": 0.01
          }, {
            "id": 5,
            "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
            "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
            "count_members": 9,
            "procent_members": 0.1
          }, {
            "id": 6,
            "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
            "title_kz": "\u041a\u0435\u043d\u0442",
            "count_members": 109,
            "procent_members": 1.18
          }, {
            "id": 7,
            "title_ru": "\u0420\u0430\u0439\u043e\u043d",
            "title_kz": "\u0410\u0443\u0434\u0430\u043d",
            "count_members": 200,
            "procent_members": 2.17
          }, {
            "id": 8,
            "title_ru": "\u0424\u0435\u0440\u043c\u0430",
            "title_kz": "\u0424\u0435\u0440\u043c\u0430",
            "count_members": 1,
            "procent_members": 0.01
          }, {
            "id": 9,
            "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
            "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
            "count_members": 0,
            "procent_members": 0
          }, {
            "id": 10,
            "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
            "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
            "count_members": 2,
            "procent_members": 0.02
          }], "regions": [{
            "id": 1,
            "title_ru": "\u0410\u043a\u043c\u043e\u043b\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u049b\u043c\u043e\u043b\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 333,
              "procent_members": 3.61
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 84,
              "procent_members": 0.91
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 18,
              "procent_members": 0.19
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 11,
              "procent_members": 0.12
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 7,
              "procent_members": 0.08
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 1,
              "procent_members": 0.01
            }]
          }, {
            "id": 2,
            "title_ru": "\u0410\u043a\u0442\u044e\u0431\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u049b\u0442\u04e9\u0431\u0435 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 317,
              "procent_members": 3.43
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 67,
              "procent_members": 0.73
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 38,
              "procent_members": 0.41
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 5,
              "procent_members": 0.05
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 19,
              "procent_members": 0.21
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 3,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 510,
              "procent_members": 5.52
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 173,
              "procent_members": 1.87
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 66,
              "procent_members": 0.71
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 23,
              "procent_members": 0.25
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 13,
              "procent_members": 0.14
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 1,
              "procent_members": 0.01
            }]
          }, {
            "id": 4,
            "title_ru": "\u0410\u0442\u044b\u0440\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u0442\u044b\u0440\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 351,
              "procent_members": 3.8
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 79,
              "procent_members": 0.86
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 27,
              "procent_members": 0.29
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 12,
              "procent_members": 0.13
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 22,
              "procent_members": 0.24
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 5,
            "title_ru": "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0428\u044b\u0493\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 568,
              "procent_members": 6.15
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 119,
              "procent_members": 1.29
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 46,
              "procent_members": 0.5
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 17,
              "procent_members": 0.18
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 16,
              "procent_members": 0.17
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 6,
            "title_ru": "\u0416\u0430\u043c\u0431\u044b\u043b\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0416\u0430\u043c\u0431\u044b\u043b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 266,
              "procent_members": 2.88
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 69,
              "procent_members": 0.75
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 42,
              "procent_members": 0.45
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 12,
              "procent_members": 0.13
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 7,
            "title_ru": "\u0417\u0430\u043f\u0430\u0434\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0411\u0430\u0442\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 415,
              "procent_members": 4.49
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 62,
              "procent_members": 0.67
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 17,
              "procent_members": 0.18
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 17,
              "procent_members": 0.18
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 10,
              "procent_members": 0.11
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 8,
            "title_ru": "\u041a\u0430\u0440\u0430\u0433\u0430\u043d\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u049a\u0430\u0440\u0430\u0493\u0430\u043d\u0434\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 131,
              "procent_members": 1.42
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 6,
              "procent_members": 0.06
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 9,
            "title_ru": "\u041a\u043e\u0441\u0442\u0430\u043d\u0430\u0439\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u043e\u0441\u0442\u0430\u043d\u0430\u0439 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 335,
              "procent_members": 3.63
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 128,
              "procent_members": 1.39
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 19,
              "procent_members": 0.21
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 5,
              "procent_members": 0.05
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 25,
              "procent_members": 0.27
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 10,
            "title_ru": "\u041a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 119,
              "procent_members": 1.29
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 59,
              "procent_members": 0.64
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 19,
              "procent_members": 0.21
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 14,
              "procent_members": 0.15
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 11,
            "title_ru": "\u041c\u0430\u043d\u0433\u0438\u0441\u0442\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u041c\u0430\u04a3\u0493\u044b\u0441\u0442\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 285,
              "procent_members": 3.09
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 143,
              "procent_members": 1.55
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 45,
              "procent_members": 0.49
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 8,
              "procent_members": 0.09
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 12,
            "title_ru": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 422,
              "procent_members": 4.57
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 89,
              "procent_members": 0.96
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 16,
              "procent_members": 0.17
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 5,
              "procent_members": 0.05
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 10,
              "procent_members": 0.11
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 13,
            "title_ru": "\u0421\u0435\u0432\u0435\u0440\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0421\u043e\u043b\u0442\u04af\u0441\u0442\u0456\u043a \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 208,
              "procent_members": 2.25
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 126,
              "procent_members": 1.36
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 16,
              "procent_members": 0.17
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 6,
              "procent_members": 0.06
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 14,
            "title_ru": "\u0422\u0443\u0440\u043a\u0435\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0422\u04af\u0440\u043a\u0456\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 224,
              "procent_members": 2.43
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 148,
              "procent_members": 1.6
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 146,
              "procent_members": 1.58
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 4,
              "procent_members": 0.04
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 11,
              "procent_members": 0.12
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 16,
            "title_ru": "\u041d\u0443\u0440-\u0421\u0443\u043b\u0442\u0430\u043d",
            "title_kz": "\u041d\u04b1\u0440-\u0421\u04b1\u043b\u0442\u0430\u043d",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 714,
              "procent_members": 7.73
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 17,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 972,
              "procent_members": 10.53
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 12,
              "procent_members": 0.13
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 6,
              "procent_members": 0.06
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 18,
            "title_ru": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "title_kz": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "types": [{
              "id": 1,
              "title_ru": "\u0413\u043e\u0440\u043e\u0434",
              "title_kz": "\u049a\u0430\u043b\u0430",
              "count_members": 755,
              "procent_members": 8.18
            }, {
              "id": 2,
              "title_ru": "\u0421\u0435\u043b\u043e",
              "title_kz": "\u0421\u0435\u043b\u043e",
              "count_members": 8,
              "procent_members": 0.09
            }, {
              "id": 3,
              "title_ru": "\u0410\u0443\u043b",
              "title_kz": "\u0410\u0443\u044b\u043b",
              "count_members": 9,
              "procent_members": 0.1
            }, {
              "id": 4,
              "title_ru": "\u041b\u0435\u0441\u0445\u043e\u0437",
              "title_kz": "\u041e\u0440\u043c\u0430\u043d \u0448\u0430\u0440\u0443\u0430\u0448\u044b\u043b\u044b\u0493\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 5,
              "title_ru": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "title_kz": "\u0421\u0442\u0430\u043d\u0446\u0438\u044f",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041f\u043e\u0441\u0435\u043b\u043e\u043a",
              "title_kz": "\u041a\u0435\u043d\u0442",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 7,
              "title_ru": "\u0420\u0430\u0439\u043e\u043d",
              "title_kz": "\u0410\u0443\u0434\u0430\u043d",
              "count_members": 18,
              "procent_members": 0.19
            }, {
              "id": 8,
              "title_ru": "\u0424\u0435\u0440\u043c\u0430",
              "title_kz": "\u0424\u0435\u0440\u043c\u0430",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 9,
              "title_ru": "\u0421\u0430\u043d\u0430\u0442\u043e\u0440\u0438\u0439",
              "title_kz": "\u0428\u0438\u043f\u0430\u0436\u0430\u0439",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 10,
              "title_ru": "\u041e\u0442\u0434\u0435\u043b\u0435\u043d\u0438\u0435",
              "title_kz": "\u0411\u04e9\u043b\u0456\u043c\u0448\u0435",
              "count_members": 0,
              "procent_members": 0
            }]
          }]
        },


        "occupation": {
          "total": [{
            "id": 1,
            "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
            "count_members": 7589,
            "procent_members": 82.99
          }, {
            "id": 2,
            "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
            "count_members": 965,
            "procent_members": 10.55
          }, {
            "id": 3,
            "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
            "count_members": 510,
            "procent_members": 5.58
          }],
          "regions": [{
            "id": 1,
            "title_ru": "\u0410\u043a\u043c\u043e\u043b\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u049b\u043c\u043e\u043b\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 342,
              "procent_members": 3.74
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 46,
              "procent_members": 0.5
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 48,
              "procent_members": 0.52
            }]
          }, {
            "id": 2,
            "title_ru": "\u0410\u043a\u0442\u044e\u0431\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u049b\u0442\u04e9\u0431\u0435 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 442,
              "procent_members": 4.83
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 3,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 661,
              "procent_members": 7.23
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 76,
              "procent_members": 0.83
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 46,
              "procent_members": 0.5
            }]
          }, {
            "id": 4,
            "title_ru": "\u0410\u0442\u044b\u0440\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u0442\u044b\u0440\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 381,
              "procent_members": 4.17
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 65,
              "procent_members": 0.71
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 39,
              "procent_members": 0.43
            }]
          }, {
            "id": 5,
            "title_ru": "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0428\u044b\u0493\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 635,
              "procent_members": 6.94
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 105,
              "procent_members": 1.15
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 1,
              "procent_members": 0.01
            }]
          }, {
            "id": 6,
            "title_ru": "\u0416\u0430\u043c\u0431\u044b\u043b\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0416\u0430\u043c\u0431\u044b\u043b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 296,
              "procent_members": 3.24
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 72,
              "procent_members": 0.79
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 16,
              "procent_members": 0.17
            }]
          }, {
            "id": 7,
            "title_ru": "\u0417\u0430\u043f\u0430\u0434\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0411\u0430\u0442\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 502,
              "procent_members": 5.49
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 28,
              "procent_members": 0.31
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 8,
            "title_ru": "\u041a\u0430\u0440\u0430\u0433\u0430\u043d\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u049a\u0430\u0440\u0430\u0493\u0430\u043d\u0434\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 126,
              "procent_members": 1.38
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 5,
              "procent_members": 0.05
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 9,
            "title_ru": "\u041a\u043e\u0441\u0442\u0430\u043d\u0430\u0439\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u043e\u0441\u0442\u0430\u043d\u0430\u0439 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 456,
              "procent_members": 4.99
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 54,
              "procent_members": 0.59
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 5,
              "procent_members": 0.05
            }]
          }, {
            "id": 10,
            "title_ru": "\u041a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 170,
              "procent_members": 1.86
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 44,
              "procent_members": 0.48
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 11,
            "title_ru": "\u041c\u0430\u043d\u0433\u0438\u0441\u0442\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u041c\u0430\u04a3\u0493\u044b\u0441\u0442\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 459,
              "procent_members": 5.02
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 12,
              "procent_members": 0.13
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 0,
              "procent_members": 0
            }]
          }, {
            "id": 12,
            "title_ru": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 475,
              "procent_members": 5.19
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 47,
              "procent_members": 0.51
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 18,
              "procent_members": 0.2
            }]
          }, {
            "id": 13,
            "title_ru": "\u0421\u0435\u0432\u0435\u0440\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0421\u043e\u043b\u0442\u04af\u0441\u0442\u0456\u043a \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 277,
              "procent_members": 3.03
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 43,
              "procent_members": 0.47
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 48,
              "procent_members": 0.52
            }]
          }, {
            "id": 14,
            "title_ru": "\u0422\u0443\u0440\u043a\u0435\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0422\u04af\u0440\u043a\u0456\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 515,
              "procent_members": 5.63
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 6,
              "procent_members": 0.07
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 1,
              "procent_members": 0.01
            }]
          }, {
            "id": 16,
            "title_ru": "\u041d\u0443\u0440-\u0421\u0443\u043b\u0442\u0430\u043d",
            "title_kz": "\u041d\u04b1\u0440-\u0421\u04b1\u043b\u0442\u0430\u043d",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 516,
              "procent_members": 5.64
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 88,
              "procent_members": 0.96
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 109,
              "procent_members": 1.19
            }]
          }, {
            "id": 17,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 682,
              "procent_members": 7.46
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 188,
              "procent_members": 2.06
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 126,
              "procent_members": 1.38
            }]
          }, {
            "id": 18,
            "title_ru": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "title_kz": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "occupations": [{
              "id": 1,
              "title_ru": "\u0428\u043a\u043e\u043b\u044c\u043d\u0438\u043a",
              "count_members": 654,
              "procent_members": 7.15
            }, {
              "id": 2,
              "title_ru": "\u0421\u0442\u0443\u0434\u0435\u043d\u0442",
              "count_members": 83,
              "procent_members": 0.91
            }, {
              "id": 3,
              "title_ru": "\u0420\u0430\u0431\u043e\u0442\u043d\u0438\u043a",
              "count_members": 53,
              "procent_members": 0.58
            }]
          }]
        }, "education_category": {
          "total": [{
            "id": 1,
            "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
            "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 6579,
            "procent_members": 71.94
          }, {
            "id": 2,
            "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
            "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 17,
            "procent_members": 0.19
          }, {
            "id": 3,
            "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
            "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 499,
            "procent_members": 5.46
          }, {
            "id": 4,
            "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
            "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 197,
            "procent_members": 2.15
          }, {
            "id": 5,
            "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
            "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 264,
            "procent_members": 2.89
          }, {
            "id": 6,
            "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
            "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
            "count_members": 1129,
            "procent_members": 12.35
          }], "regions": [{
            "id": 1,
            "title_ru": "\u0410\u043a\u043c\u043e\u043b\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u049b\u043c\u043e\u043b\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 266,
              "procent_members": 2.91
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 28,
              "procent_members": 0.31
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 8,
              "procent_members": 0.09
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 8,
              "procent_members": 0.09
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 67,
              "procent_members": 0.73
            }]
          }, {
            "id": 2,
            "title_ru": "\u0410\u043a\u0442\u044e\u0431\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u049b\u0442\u04e9\u0431\u0435 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 389,
              "procent_members": 4.25
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 16,
              "procent_members": 0.17
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 3,
              "procent_members": 0.03
            }]
          }, {
            "id": 3,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 568,
              "procent_members": 6.21
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 91,
              "procent_members": 1
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 26,
              "procent_members": 0.28
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 23,
              "procent_members": 0.25
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 110,
              "procent_members": 1.2
            }]
          }, {
            "id": 4,
            "title_ru": "\u0410\u0442\u044b\u0440\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0410\u0442\u044b\u0440\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 326,
              "procent_members": 3.56
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 1,
              "procent_members": 0.01
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 15,
              "procent_members": 0.16
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 18,
              "procent_members": 0.2
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 24,
              "procent_members": 0.26
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 75,
              "procent_members": 0.82
            }]
          }, {
            "id": 5,
            "title_ru": "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0428\u044b\u0493\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 566,
              "procent_members": 6.19
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 6,
              "procent_members": 0.07
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 22,
              "procent_members": 0.24
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 33,
              "procent_members": 0.36
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 43,
              "procent_members": 0.47
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 43,
              "procent_members": 0.47
            }]
          }, {
            "id": 6,
            "title_ru": "\u0416\u0430\u043c\u0431\u044b\u043b\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0416\u0430\u043c\u0431\u044b\u043b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 267,
              "procent_members": 2.92
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 15,
              "procent_members": 0.16
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 48,
              "procent_members": 0.52
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 36,
              "procent_members": 0.39
            }]
          }, {
            "id": 7,
            "title_ru": "\u0417\u0430\u043f\u0430\u0434\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u0411\u0430\u0442\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 425,
              "procent_members": 4.65
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 30,
              "procent_members": 0.33
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 20,
              "procent_members": 0.22
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }]
          }, {
            "id": 8,
            "title_ru": "\u041a\u0430\u0440\u0430\u0433\u0430\u043d\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u049a\u0430\u0440\u0430\u0493\u0430\u043d\u0434\u044b \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 93,
              "procent_members": 1.02
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 18,
              "procent_members": 0.2
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 9,
              "procent_members": 0.1
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 1,
              "procent_members": 0.01
            }]
          }, {
            "id": 9,
            "title_ru": "\u041a\u043e\u0441\u0442\u0430\u043d\u0430\u0439\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u043e\u0441\u0442\u0430\u043d\u0430\u0439 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 407,
              "procent_members": 4.45
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 14,
              "procent_members": 0.15
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 11,
              "procent_members": 0.12
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 5,
              "procent_members": 0.05
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 52,
              "procent_members": 0.57
            }]
          }, {
            "id": 10,
            "title_ru": "\u041a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u049a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0430 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 173,
              "procent_members": 1.89
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 8,
              "procent_members": 0.09
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 6,
              "procent_members": 0.07
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 25,
              "procent_members": 0.27
            }]
          }, {
            "id": 11,
            "title_ru": "\u041c\u0430\u043d\u0433\u0438\u0441\u0442\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
            "title_kz": "\u041c\u0430\u04a3\u0493\u044b\u0441\u0442\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 433,
              "procent_members": 4.73
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 6,
              "procent_members": 0.07
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 11,
              "procent_members": 0.12
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 9,
              "procent_members": 0.1
            }]
          }, {
            "id": 12,
            "title_ru": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e",
            "title_kz": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440 \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 378,
              "procent_members": 4.13
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 55,
              "procent_members": 0.6
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 28,
              "procent_members": 0.31
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 13,
              "procent_members": 0.14
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 36,
              "procent_members": 0.39
            }]
          }, {
            "id": 13,
            "title_ru": "\u0421\u0435\u0432\u0435\u0440\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0421\u043e\u043b\u0442\u04af\u0441\u0442\u0456\u043a \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 262,
              "procent_members": 2.86
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 14,
              "procent_members": 0.15
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 4,
              "procent_members": 0.04
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 19,
              "procent_members": 0.21
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 61,
              "procent_members": 0.67
            }]
          }, {
            "id": 14,
            "title_ru": "\u0422\u0443\u0440\u043a\u0435\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c\u200e ",
            "title_kz": "\u0422\u04af\u0440\u043a\u0456\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b\r\n",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 452,
              "procent_members": 4.94
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 3,
              "procent_members": 0.03
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 26,
              "procent_members": 0.28
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 4,
              "procent_members": 0.04
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 4,
              "procent_members": 0.04
            }]
          }, {
            "id": 16,
            "title_ru": "\u041d\u0443\u0440-\u0421\u0443\u043b\u0442\u0430\u043d",
            "title_kz": "\u041d\u04b1\u0440-\u0421\u04b1\u043b\u0442\u0430\u043d",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 457,
              "procent_members": 5
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 25,
              "procent_members": 0.27
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 13,
              "procent_members": 0.14
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 19,
              "procent_members": 0.21
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 175,
              "procent_members": 1.91
            }]
          }, {
            "id": 17,
            "title_ru": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "title_kz": "\u0410\u043b\u043c\u0430\u0442\u044b",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 538,
              "procent_members": 5.88
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 0,
              "procent_members": 0
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 68,
              "procent_members": 0.74
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 12,
              "procent_members": 0.13
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 25,
              "procent_members": 0.27
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 311,
              "procent_members": 3.4
            }]
          }, {
            "id": 18,
            "title_ru": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "title_kz": "\u0428\u044b\u043c\u043a\u0435\u043d\u0442",
            "categories": [{
              "id": 1,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0441\u0440\u0435\u0434\u043d\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041e\u0440\u0442\u0430 \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 579,
              "procent_members": 6.33
            }, {
              "id": 2,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f \u0434\u043b\u044f \u0434\u0435\u0442\u0435\u0439-\u0441\u0438\u0440\u043e\u0442 \u0438 \u0434\u0435\u0442\u0435\u0439",
              "title_kz": "\u0416\u0435\u0442\u0456\u043c \u0431\u0430\u043b\u0430\u043b\u0430\u0440 \u0430\u0440\u043d\u0430\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 2,
              "procent_members": 0.02
            }, {
              "id": 3,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0437\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u041c\u0430\u043c\u0430\u043d\u0434\u0430\u043d\u0434\u044b\u0440\u044b\u043b\u0493\u0430\u043d \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443  \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 48,
              "procent_members": 0.52
            }, {
              "id": 4,
              "title_ru": "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0410\u0440\u043d\u0430\u0439\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 7,
              "procent_members": 0.08
            }, {
              "id": 5,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0442\u0435\u0445\u043d\u0438\u0447\u0435\u0441\u043a\u043e\u0433\u043e \u0438 \u043f\u0440\u043e\u0444\u0435\u0441\u0441\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0422\u0435\u0445\u043d\u0438\u043a\u0430\u043b\u044b\u049b \u0436\u04d9\u043d\u0435 \u043a\u04d9\u0441\u0456\u043f\u0442\u0456\u043a \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 11,
              "procent_members": 0.12
            }, {
              "id": 6,
              "title_ru": "\u041e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438 \u0432\u044b\u0441\u0448\u0435\u0433\u043e \u043e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u043d\u0438\u044f",
              "title_kz": "\u0416\u043e\u0493\u0430\u0440\u044b \u0431\u0456\u043b\u0456\u043c \u0431\u0435\u0440\u0443 \u04b1\u0439\u044b\u043c\u0434\u0430\u0440\u044b",
              "count_members": 119,
              "procent_members": 1.3
            }]
          }]
        }
      }
    }
  },
  filters: {
    Numerize: function (value) {
      if (value == 0) return '0'
      if (!value) return ''
      if (value <= 999) return value
      return value.toLocaleString().replace(',', ' ')
    }
  },
  mounted() {
    this.loading = true
    this.loadingFilterTable2 = true
    this.loadingFilterTable2_1 = true
    this.loadingFilterTable2_2 = true
    // this.cityTable = this.paramsBody.city_types.regions
    // this.occupationTable = this.paramsBody.occupation.regions
    // this.occupationTableAll = this.paramsBody.occupation.total
    // this.educationTable = this.paramsBody.education_category.regions
    // this.educationTableAll = this.paramsBody.education_category.total
    // this.membersEducationSeries = []
    // this.membersOccupationSeries = []
    // this.membersCitySeries = []
    // this.membersCityOptions.labels = []
    // this.membersOccupationOptions.labels = []
    // this.cityTableAll = this.paramsBody.city_types.total
    // for (var i = 0; i < this.paramsBody.city_types.total.length; i++) {
    //   if(this.locale=='ru'){
    //     this.membersCityOptions.labels.push(this.paramsBody.city_types.total[i].title_ru)
    //   }
    //   if(this.locale=='kz'){
    //     this.membersCityOptions.labels.push(this.paramsBody.city_types.total[i].title_kz)
    //   }
    //   this.membersCitySeries.push(this.paramsBody.city_types.total[i].count_members)
    // }
    // for (var x = 0; x < this.paramsBody.occupation.total.length; x++) {
    //   this.membersOccupationOptions.labels.push(this.paramsBody.occupation.total[x].title_ru)
    //   this.membersOccupationSeries.push(this.paramsBody.occupation.total[x].count_members)
    // }
    // for (var y = 0; y < this.paramsBody.education_category.total.length; y++) {
    //   this.membersEducationOptions.labels.push(this.paramsBody.education_category.total[y].title_ru)
    //   this.membersEducationSeries.push(this.paramsBody.education_category.total[y].count_members)
    // }


    var currentYear = new Date().getFullYear()
    var max = currentYear
    for (var year = 2020; year <= max; year++) {
      this.years.push({'name': year, 'id': year})
    }
    this.yearTo = this.years[this.years.length - 1].name

    this.medalDateFrom = new Date(new Date().getFullYear() - 1, 0, 1)
    this.medalDateTo = new Date(new Date().getFullYear() - 1, 11, 31)
    var dd = this.medalDateFrom.getDate();
    var mm = this.medalDateFrom.getMonth() + 1;
    var yyyy = this.medalDateFrom.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    this.medalDateFrom = yyyy + '-' + mm + '-' + dd;

    dd = this.medalDateTo.getDate();
    mm = this.medalDateTo.getMonth() + 1;
    yyyy = this.medalDateTo.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    this.yearFrom = this.yearTo - 1
    this.medalDateTo = yyyy + '-' + mm + '-' + dd;
    this.DirectionDateTo = this.yearTo
    this.DirectionDateFrom = this.yearFrom
    this.TaskDateFrom = this.medalDateFrom
    this.TaskDateTo = this.medalDateTo
    this.GenderDateTo = this.medalDateTo
    this.GenderDateFrom = this.medalDateFrom

    this.$http.post(window.medalElbasy + '/api/v1/login', {
      'email': window.medalElbasyLogin,
      'password': window.medalElbasyPassword,
    })
        .then((res) => {
          localStorage.setItem('MedalElbasyToken', res.body.data.api_token)
          this.chartLoad = true
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/age').then((res) => {
            var membersArray = []
            var winnersArray = []
            var reservedArray = []
            var rejectedArray = []
            for (var i = 0; i < res.body.total.ages.length; i++) {
              this.chartOptionsAge.xaxis.categories.push(res.body.total.ages[i].age + ' ' + this.$t('МедальЕлбасы.лет'))
              membersArray.push(res.body.total.ages[i].count_members)
              winnersArray.push(res.body.total.ages[i].count_winners)
              reservedArray.push(res.body.total.ages[i].count_reserved)
              rejectedArray.push(res.body.total.ages[i].count_rejected)
            }

            this.seriesAge.push(
                {
                  name: this.$t('МедальЕлбасы.Активныеучастники'),
                  data: membersArray
                }
            )
            this.seriesAge.push(
                {
                  name: this.$t('МедальЕлбасы.Победители'),
                  data: winnersArray
                }
            )
            this.seriesAge.push(
                {
                  name: this.$t('МедальЕлбасы.Резерв'),
                  data: reservedArray
                }
            )
            this.seriesAge.push(
                {
                  name: this.$t('МедальЕлбасы.Отклонен'),
                  data: rejectedArray
                }
            )
            this.chartLoad = true
            this.loadingApi.membersAge = false
          })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members')
              .then((res) => {
                this.members = res.body
                this.mentors = res.body.count_mentors
                this.count_orders = res.body.count_orders
                this.membersCount = res.body.count_confirmed + res.body.count_moderated + res.body.count_created + res.body.count_rejected
                this.loadingApi.members = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/books/popular')
              .then((res) => {
                this.booksPopular = res.body[0]
                this.loadingApi.books = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/sport/popular')
              .then((res) => {
                this.sportPopular = res.body[0]
                this.loadingApi.sport = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/age?date_from=' + this.medalDateFrom + "&date_to=" + this.medalDateTo)
              .then((res) => {
                this.applications = res.body.regions
                this.applicationsToggle = []
                for (var i = 0; i < this.applications.length; i++) {
                  var peopleCount = 0
                  for (var ageIndex = 0; ageIndex < this.applications[i].ages.length; ageIndex++) {
                    peopleCount = peopleCount + this.applications[i].ages[ageIndex].count_winners
                  }
                  this.applications[i].agesCount = peopleCount
                }
                for (var x = 0; x < this.applications.length; x++) {
                  this.applicationsToggle.push(false)
                }
                this.loadingApi.membersAgeDate = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/kpi')
              .then((res) => {
                var arrPoint = []
                var namesArray = []
                var arrDirections = []
                this.kpi = res.body.data
                this.kpiMeta = res.body.meta


                for (var x = 0; x < 1; x++) {
                  arrPoint = []
                  for (var y = 0; y < res.body.data[x].directions.length; y++) {
                    arrPoint.push(res.body.data[x].directions[y].progress)
                    this.chartOptionsKpi.yaxis.max = res.body.data[x].plan_kpi
                    if (this.locale == 'ru') {
                      arrDirections.push(res.body.data[x].directions[y].title_ru)
                    }
                    if (this.locale == 'kz') {
                      arrDirections.push(res.body.data[x].directions[y].title_kz)
                    }
                    arrDirections = [...new Set(arrDirections)];
                  }
                  namesArray.push({
                    'name': res.body.data[x].name,
                    'data': arrPoint
                  })
                }
                for (var el = 0; el < arrDirections.length; el++) {
                  this.chartOptionsKpi.xaxis.categories.push(arrDirections[el])
                }

                this.seriesKpi = namesArray
                this.currentKpi = this.kpi[0].current_kpi
                this.loadingApi.kpi = false

              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/directions' + "?date_to=" + this.DirectionDateTo + "&date_from=" + this.DirectionDateFrom)
              .then((res) => {
                this.directions = res.body
                for (var i = 0; i < this.directions.length; i++) {
                  this.directions[i].allCount = this.directions[i].tasks.count_new + this.directions[i].tasks.count_on_moving + this.directions[i].tasks.count_moved + this.directions[i].tasks.count_on_confirmation + this.directions[i].tasks.count_confirmed + this.directions[i].tasks.count_refused + this.directions[i].tasks.count_waited + this.directions[i].tasks.count_not_performed
                }
                this.loadingApi.directions = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/tasks/statistic' + '?date_to=' + this.TaskDateTo + '&date_from=' + this.TaskDateFrom)
              .then((res) => {
                this.mentorsArea = res.body.data
                this.statsMeta = res.body.meta
                this.loadingApi.mentors = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/countries/1/areas')
              .then((res) => {
                this.regions = res.body.data
                this.loadingApi.areas = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/gender/area' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.membersGenderArea = res.body
                for (var i = 0; i < this.membersGenderArea.length; i++) {
                  this.membersGender.count_male = this.membersGender.count_male + this.membersGenderArea[i].count_male
                  this.membersGender.count_female = this.membersGender.count_female + this.membersGenderArea[i].count_female
                }

                this.genderChartSeries.push(
                    this.membersGender.count_male
                )
                this.genderChartSeries.push(
                    this.membersGender.count_female
                )
                this.loadingApi.membersGender = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/gender' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.mentorsGenderArea = res.body.regions
                for (var i = 0; i < this.mentorsGenderArea.length; i++) {
                  this.mentorsGender.count_male = this.mentorsGender.count_male + this.mentorsGenderArea[i].count_male
                  this.mentorsGender.count_female = this.mentorsGender.count_female + this.mentorsGenderArea[i].count_female
                }
                this.genderChartMentorsSeries.push(
                    this.mentorsGender.count_male
                )
                this.genderChartMentorsSeries.push(
                    this.mentorsGender.count_female
                )
                this.loadingApi.mentorsGender = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/types' + '?date_to=' + this.TaskDateTo + '&date_from=' + this.TaskDateFrom)
              .then((res) => {
                this.fotoContent = res.body.count_tasks_images
                this.videoContent = res.body.count_tasks_videos
                this.loadingApi.tasksType = false
              })


          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/citytypes' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.cityTable = res.body.city_types.regions
                this.cityTableAll = res.body.city_types.total
                for (var i = 0; i < res.body.city_types.total.length; i++) {
                  if (this.locale == 'ru') {
                    this.membersCityOptions.labels.push(res.body.city_types.total[i].title_ru)
                  }
                  if (this.locale == 'kz') {
                    this.membersCityOptions.labels.push(res.body.city_types.total[i].title_kz)
                  }
                  this.membersCitySeries.push(res.body.city_types.total[i].count_members)
                }
                this.loadingFilterTable2 = false
              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/occupation' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.occupationTable = res.body.occupation.regions
                this.occupationTableAll = res.body.occupation.total
                for (var x = 0; x < res.body.occupation.total.length; x++) {
                  if (this.locale == 'ru') {
                    this.membersOccupationOptions.labels.push(res.body.occupation.total[x].title_ru)
                  }
                  if (this.locale == 'kz') {
                    this.membersOccupationOptions.labels.push(res.body.occupation.total[x].title_kz)
                  }
                  this.membersOccupationSeries.push(res.body.occupation.total[x].count_members)
                }
                this.loadingFilterTable2_1 = false

              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/education' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.educationTable = res.body.education_category.regions
                this.educationTableAll = res.body.education_category.total
                for (var y = 0; y < res.body.education_category.total.length; y++) {
                  if (this.locale == 'ru') {
                    this.membersEducationOptions.labels.push(res.body.education_category.total[y].title_ru)
                  }
                  if (this.locale == 'kz') {
                    this.membersEducationOptions.labels.push(res.body.education_category.total[y].title_kz)
                  }
                  this.membersEducationSeries.push(res.body.education_category.total[y].count_members)
                }
                this.loadingFilterTable2_2 = false

              })


          this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/age' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom)
              .then((res) => {
                this.ageTable = res.body.regions
                this.ageTableAll = res.body.total
                var mentorsAgeSeries = []
                var ageChartMentorsOptions = []
                var mentorsAgeSeries1 = [0, 0, 0, 0]
                for (var i = 0; i < res.body.total.ages.length; i++) {
                  mentorsAgeSeries.push(res.body.total.ages[i].count_mentors)
                  ageChartMentorsOptions.push(res.body.total.ages[i].age)
                }
                for (var x = 0; x < mentorsAgeSeries.length; x++) {

                  if (ageChartMentorsOptions[x] >= 20 && ageChartMentorsOptions[x] <= 29) {

                    mentorsAgeSeries1[0] = mentorsAgeSeries1[0] + mentorsAgeSeries[x]

                  }
                  if (ageChartMentorsOptions[x] >= 30 && ageChartMentorsOptions[x] <= 39) {

                    mentorsAgeSeries1[1] = mentorsAgeSeries1[1] + mentorsAgeSeries[x]

                  }
                  if (ageChartMentorsOptions[x] >= 50 && ageChartMentorsOptions[x] <= 59) {

                    mentorsAgeSeries1[2] = mentorsAgeSeries1[2] + mentorsAgeSeries[x]

                  }
                  if (ageChartMentorsOptions[x] >= 60 && ageChartMentorsOptions[x] <= 69) {

                    mentorsAgeSeries1[3] = mentorsAgeSeries1[3] + mentorsAgeSeries[x]

                  }
                }
                this.mentorsAgeSeries = mentorsAgeSeries1
                this.loadingApi.mentorsAgeSeries = false

              })
          this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/program?date_from=' + this.medalDateFrom + "&date_to=" + this.medalDateTo)
              .then((res) => {
                var arrWin = [0, 0, 0]
                var arrRes = [0, 0, 0]
                var arrActive = [0, 0, 0]
                for (var i = 0; i < res.body.length; i++) {
                  arrRes[0] = arrRes[0] + res.body[i].program_levels[2].count_reserved
                  arrRes[1] = arrRes[1] + res.body[i].program_levels[1].count_reserved
                  arrRes[2] = arrRes[2] + res.body[i].program_levels[0].count_reserved

                  arrActive[0] = arrActive[0] + res.body[i].program_levels[2].count_confirmed
                  arrActive[1] = arrActive[1] + res.body[i].program_levels[1].count_confirmed
                  arrActive[2] = arrActive[2] + res.body[i].program_levels[0].count_confirmed

                  arrWin[0] = arrWin[0] + res.body[i].program_levels[2].count_winners
                  arrWin[1] = arrWin[1] + res.body[i].program_levels[1].count_winners
                  arrWin[2] = arrWin[2] + res.body[i].program_levels[0].count_winners
                }


                arrWin = arrWin.map(function (num) {
                  if (isNaN(num)) {
                    return 0
                  } else {
                    return num
                  }
                })
                arrRes = arrRes.map(function (num) {
                  if (isNaN(num)) {
                    return 0
                  } else {
                    return num
                  }
                })
                arrActive = arrActive.map(function (num) {
                  if (isNaN(num)) {
                    return 0
                  } else {
                    return num
                  }
                })


                this.Winnerseries = arrWin
                this.Reservseries = arrRes
                this.Activeseries = arrActive
                this.loadingApi.membersProgram = false
              })

          this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/directions' + '?date_to=' + this.TaskDateTo + '&date_from=' + this.TaskDateFrom)
              .then((res) => {
                var count_on_confirmation = []
                var count_refused = []
                var count_waited = []
                var count_not_performed = []
                var allCount = []
                var count_confirmed = []
                var count_new = []
                var count_expired_checking = []

                function sum(x) {
                  var s = 0;
                  for (i = 0; i < x.length; i++) {
                    s += x[i]
                  }
                  return s
                }

                for (var i = 0; i < res.body.length; i++) {
                  count_expired_checking.push(res.body[i].tasks.count_expired_checking)
                  count_new.push(res.body[i].tasks.count_new)
                  allCount.push(Object.values(res.body[i].tasks))
                  count_confirmed.push(res.body[i].tasks.count_confirmed)
                  count_not_performed.push(res.body[i].tasks.count_not_performed)
                  count_waited.push(res.body[i].tasks.count_waited)
                  count_refused.push(res.body[i].tasks.count_refused)
                  count_on_confirmation.push(res.body[i].tasks.count_on_confirmation)
                }

                for (var x = 0; x < res.body.length; x++) {
                  allCount[x] = sum(allCount[x])
                }
                this.tasksDirections.count_expired_checking = sum(count_expired_checking)
                this.tasksDirections.count_waited = sum(count_waited)
                this.tasksDirections.count_not_performed = sum(count_not_performed)
                this.tasksDirections.count_new = sum(count_new)
                this.tasksDirections.count_confirmed = sum(count_confirmed)
                this.tasksDirections.allCount = sum(allCount)
                this.tasksDirections.count_refused = sum(count_refused)
                this.tasksDirections.count_on_confirmation = sum(count_on_confirmation)
                this.loadingApi.membersDirections = false
              })

        })
        .catch((e) => {
          // eslint-disable-next-line no-debugger
          debugger;
          // this.$router.go()
        })
  },
  watch: {
    'loadingFilterTable1'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingFilterTable2'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingFilterTable2_1'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingFilterTable2_2'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingFilterTable3'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingFilterTable4'() {
      if (this.loadingFilterTable1 == false && this.loadingFilterTable2 == false && this.loadingFilterTable2_1 == false && this.loadingFilterTable2_2 == false && this.loadingFilterTable3 == false && this.loadingFilterTable4 == false) {
        this.loadingFilterTable = false
      } else {
        this.loadingFilterTable = true
      }
    },
    'loadingApi': {
      handler(newValue, oldValue) {
        this.loading = !Object.values(this.loadingApi).every(elem => elem == false)

      },
      deep: true
    },


    seriesAge() {
      if (this.seriesAge.length == 4) {
        this.chartLoad = false
      }
      if (this.seriesAge < 4) {
        this.chartLoad.length = true
      }
    },
    regionAgeFilterRole() {
      if (this.chartLoad == false) {
        this.ageChartFilter()
      }
    },
    winnersAgeFilter() {
      this.filterApplication()

    },
    medalDateTo() {
      this.filterApplication(this.medalDateTo)
    },
    medalDateFrom() {
      this.filterApplication(this.medalDateFrom)
    },
    kpiFio() {
      if (this.kpiFio == '') {
        this.kpiSearch()
      }
    }
  },
  methods: {
    clearWinner() {
      this.medalDateFrom = new Date(new Date().getFullYear() - 1, 0, 1)
      this.medalDateTo = new Date()
      var dd = this.medalDateFrom.getDate();
      var mm = this.medalDateFrom.getMonth() + 1;
      var yyyy = this.medalDateFrom.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      this.medalDateFrom = yyyy + '-' + mm + '-' + dd;

      dd = this.medalDateTo.getDate();
      mm = this.medalDateTo.getMonth() + 1;
      yyyy = this.medalDateTo.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      this.medalDateTo = yyyy + '-' + mm + '-' + dd;
      this.filterApplication()
    },
    changeLang() {
      this.loading = true
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },
    showModal(id) {
      this.directionsId = id - 1

      this.$bvModal.show('modal-item')
    },
    filterTask(id, clear = false) {
      if (clear == true) {
        this.TaskDateFrom = new Date(new Date().getFullYear() - 1, 0, 1)
        this.TaskDateTo = new Date()
        var dd = this.TaskDateFrom.getDate();
        var mm = this.TaskDateFrom.getMonth() + 1;
        var yyyy = this.TaskDateFrom.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        }
        if (mm < 10) {
          mm = '0' + mm;
        }
        this.TaskDateFrom = yyyy + '-' + mm + '-' + dd;

        dd = this.TaskDateTo.getDate();
        mm = this.TaskDateTo.getMonth() + 1;
        yyyy = this.TaskDateTo.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        }
        if (mm < 10) {
          mm = '0' + mm;
        }
        this.TaskDateTo = yyyy + '-' + mm + '-' + dd;
      }
      if (this.TaskDateFrom == null) {
        this.TaskDateFrom = ''
      }
      if (this.TaskDateTo == null) {
        this.TaskDateTo = ''
      }
      if (id.length > 2) {
        id = 0
      }
      var url = '?area_id=' + id
      if (id == 0) {
        url = ''
      }

      if (id == 0) {
        if (this.TaskDateFrom != '') {
          url = '?date_from=' + this.TaskDateFrom
        }
        if (this.TaskDateTo != '') {
          url = '?date_to=' + this.TaskDateTo
        }
        if (this.TaskDateTo != '' && this.TaskDateTo != '') {
          url = '?date_to=' + this.TaskDateTo + '&date_from=' + this.TaskDateFrom
        }
      } else if (id != 0) {
        if (this.TaskDateFrom != '') {
          url = '?date_from=' + this.TaskDateFrom + '&area_id=' + id
        }
        if (this.TaskDateTo != '') {
          url = '?date_to=' + this.TaskDateTo + '&area_id=' + id
        }
        if (this.TaskDateTo != '' && this.TaskDateTo != '') {
          url = '?date_to=' + this.TaskDateTo + '&date_from=' + this.TaskDateFrom + '&area_id=' + id
        }
      }


      this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/types' + url)
          .then((res) => {
            this.fotoContent = res.body.count_tasks_images
            this.videoContent = res.body.count_tasks_videos
          })
      this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/tasks/statistic' + url)
          .then((res) => {
            this.mentorsArea = res.body.data
            this.statsMeta = res.body.meta
          })
      this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/directions' + url)
          .then((res) => {
            var count_on_confirmation = []
            var count_refused = []
            var count_waited = []
            var count_not_performed = []
            var allCount = []
            var count_confirmed = []
            var count_new = []
            var count_expired_checking = []

            function sum(x) {
              var s = 0;
              for (i = 0; i < x.length; i++) {
                s += x[i]
              }
              return s
            }

            for (var i = 0; i < res.body.length; i++) {
              count_expired_checking.push(res.body[i].tasks.count_expired_checking)
              count_new.push(res.body[i].tasks.count_new)
              allCount.push(Object.values(res.body[i].tasks))
              count_confirmed.push(res.body[i].tasks.count_confirmed)
              count_not_performed.push(res.body[i].tasks.count_not_performed)
              count_waited.push(res.body[i].tasks.count_waited)
              count_refused.push(res.body[i].tasks.count_refused)
              count_on_confirmation.push(res.body[i].tasks.count_on_confirmation)
            }

            for (var x = 0; x < res.body.length; x++) {
              allCount[x] = sum(allCount[x])
            }
            this.tasksDirections.count_expired_checking = sum(count_expired_checking)
            this.tasksDirections.count_waited = sum(count_waited)
            this.tasksDirections.count_not_performed = sum(count_not_performed)
            this.tasksDirections.count_new = sum(count_new)
            this.tasksDirections.count_confirmed = sum(count_confirmed)
            this.tasksDirections.allCount = sum(allCount)
            this.tasksDirections.count_refused = sum(count_refused)
            this.tasksDirections.count_on_confirmation = sum(count_on_confirmation)
          })

    },


    filterGender(clear = false) {
      if (this.loadingFilterTable == false) {
        if (clear == true) {
          this.regionGender = ''
          this.filterTable.genderMember = ''
          this.filterTable.cityType = ''
          this.filterTable.occupation = ''
          this.filterTable.genderMentor = ''
          this.filterTable.ageMentor = ''
          this.filterTable.education_category = ""
          this.filterTable.genderMentor = ""
          this.filterTable.occupation = ""

          this.GenderDateFrom = new Date(new Date().getFullYear() - 1, 0, 1)
          this.GenderDateTo = new Date()
          var dd = this.GenderDateFrom.getDate();
          var mm = this.GenderDateFrom.getMonth() + 1;
          var yyyy = this.GenderDateFrom.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          this.GenderDateFrom = yyyy + '-' + mm + '-' + dd;

          dd = this.GenderDateTo.getDate();
          mm = this.GenderDateTo.getMonth() + 1;
          yyyy = this.GenderDateTo.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          this.GenderDateTo = yyyy + '-' + mm + '-' + dd;


        }
        var regionGender = this.regionGender
        var ageMentorFrom = this.filterTable.ageMentor
        var ageMentorTo = this.filterTable.ageMentor

        var genderMember = 0
        var genderMentor = 0
        var education_category = 0
        var city_type = 0
        var occupation = 0

        city_type = this.membersCityOptions.labels.indexOf(this.filterTable.cityType) + 1

        if (regionGender == 0) {
          this.regionGender = ''
        }
        if (this.filterTable.ageMentor != 0) {
          ageMentorFrom = ageMentorFrom.substr(0, 2)
          ageMentorTo = ageMentorTo.substr(3, 4)
        } else {
          ageMentorFrom = ''
          ageMentorTo = ''
        }

        if (city_type == 0) {
          city_type = ''
        }

        education_category = this.filterTable.education_category


        if (this.filterTable.genderMentor == this.$t('МедальЕлбасы.мужской')) {
          genderMentor = 1
        } else if (this.filterTable.genderMentor == this.$t('МедальЕлбасы.женский')) {
          genderMentor = 2
        } else {
          genderMentor = ''
        }
        if (this.filterTable.genderMember == this.$t('МедальЕлбасы.мужской')) {
          genderMember = 1
        } else if (this.filterTable.genderMember == this.$t('МедальЕлбасы.женский')) {
          genderMember = 2
        } else {
          genderMember = ''
        }
        if (this.filterTable.occupation == 'Школьник') {
          occupation = 1
        }
        if (this.filterTable.occupation == 'Студент') {
          occupation = 2
        }
        if (this.filterTable.occupation == 'Работник') {
          occupation = 3
        }
        if (this.filterTable.occupation == 0) {
          occupation = ''
        }


        this.membersGender.count_male = 0
        this.membersGender.count_female = 0
        this.mentorsGender.count_male = 0
        this.mentorsGender.count_female = 0
        if (this.GenderDateTo == null) {
          this.GenderDateTo = ''
        }
        if (this.GenderDateFrom == null) {
          this.GenderDateFrom = ''
        }

        this.loadingFilterTable1 = true
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/age' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + regionGender + '&gender=' + genderMentor + '&age_from=' + ageMentorFrom + '&age_to=' + ageMentorTo)
            .then((res) => {
              this.ageTable = res.body.regions
              this.ageTableAll = res.body.total
              var mentorsAgeSeries = []
              var ageChartMentorsOptions = []
              var mentorsAgeSeries1 = [0, 0, 0, 0]
              for (var i = 0; i < res.body.total.ages.length; i++) {
                mentorsAgeSeries.push(res.body.total.ages[i].count_mentors)
                ageChartMentorsOptions.push(res.body.total.ages[i].age)
              }
              for (var x = 0; x < mentorsAgeSeries.length; x++) {
                if (ageChartMentorsOptions[x] >= 20 && ageChartMentorsOptions[x] <= 29) {
                  mentorsAgeSeries1[0] = mentorsAgeSeries1[0] + mentorsAgeSeries[x]
                }
                if (ageChartMentorsOptions[x] >= 30 && ageChartMentorsOptions[x] <= 39) {
                  mentorsAgeSeries1[1] = mentorsAgeSeries1[1] + mentorsAgeSeries[x]
                }
                if (ageChartMentorsOptions[x] >= 50 && ageChartMentorsOptions[x] <= 59) {
                  mentorsAgeSeries1[2] = mentorsAgeSeries1[2] + mentorsAgeSeries[x]
                }
                if (ageChartMentorsOptions[x] >= 60 && ageChartMentorsOptions[x] <= 69) {
                  mentorsAgeSeries1[3] = mentorsAgeSeries1[3] + mentorsAgeSeries[x]
                }

              }
              this.mentorsAgeSeries = mentorsAgeSeries1
              this.loadingFilterTable1 = false

            })

        this.loadingFilterTable2 = true
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/citytypes' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMember + '&education_category_id=' + education_category + '&type_city=' + city_type + '&occupation=' + occupation)
            .then((res) => {
              this.cityTable = res.body.city_types.regions
              this.membersCitySeries = []
              this.membersCityOptions.labels = []
              this.cityTableAll = res.body.city_types.total
              for (var i = 0; i < res.body.city_types.total.length; i++) {

                if (this.locale == 'ru') {
                  this.membersCityOptions.labels.push(res.body.city_types.total[i].title_ru)
                }
                if (this.locale == 'kz') {
                  this.membersCityOptions.labels.push(res.body.city_types.total[i].title_kz)
                }
                this.membersCitySeries.push(res.body.city_types.total[i].count_members)
              }
              this.loadingFilterTable2 = false
            })
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/occupation' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMember + '&education_category_id=' + education_category + '&type_city=' + city_type + '&occupation=' + occupation)
            .then((res) => {
              this.occupationTable = res.body.occupation.regions
              this.occupationTableAll = res.body.occupation.total
              this.membersOccupationSeries = []
              this.membersOccupationOptions.labels = []
              for (var x = 0; x < res.body.occupation.total.length; x++) {

                if (this.locale == 'ru') {
                  this.membersOccupationOptions.labels.push(res.body.occupation.total[x].title_ru)
                }
                if (this.locale == 'kz') {
                  this.membersOccupationOptions.labels.push(res.body.occupation.total[x].title_kz)
                }
                this.membersOccupationSeries.push(res.body.occupation.total[x].count_members)
              }
              this.loadingFilterTable2_1 = false

            })
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/education' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMember + '&education_category_id=' + education_category + '&type_city=' + city_type + '&occupation=' + occupation)
            .then((res) => {
              this.educationTable = res.body.education_category.regions
              this.educationTableAll = res.body.education_category.total
              this.membersEducationOptions.labels = []
              this.membersEducationSeries = []
              for (var y = 0; y < res.body.education_category.total.length; y++) {

                if (this.locale == 'ru') {
                  this.membersEducationOptions.labels.push(res.body.education_category.total[y].title_ru)
                }
                if (this.locale == 'kz') {
                  this.membersEducationOptions.labels.push(res.body.education_category.total[y].title_kz)
                }
                this.membersEducationSeries.push(res.body.education_category.total[y].count_members)
              }
              this.loadingFilterTable2_2 = false

            })

        // this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/params' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMember + '&education_category_id=' + education_category + '&type_city=' + city_type + '&occupation=' + occupation)
        //     .then((res) => {
        //       this.cityTable = res.body.city_types.regions
        //       this.occupationTable = res.body.occupation.regions
        //       this.occupationTableAll = res.body.occupation.total
        //       this.educationTable = res.body.education_category.regions
        //       this.educationTableAll = res.body.education_category.total
        //       this.cityTableAll = res.body.city_types.total
        //       this.membersEducationSeries = []
        //       this.membersCitySeries = []
        //
        //       for (var i = 0; i < res.body.city_types.total.length; i++) {
        //         this.membersCitySeries.push(res.body.city_types.total[i].count_members)
        //       }
        //       for (var x = 0; x < res.body.occupation.total.length; x++) {
        //         this.membersOccupationSeries.push(res.body.occupation.total[x].count_members)
        //       }
        //       for (var y = 0; y < res.body.education_category.total.length; y++) {
        //         this.membersEducationSeries.push(res.body.education_category.total[y].count_members)
        //       }
        //       this.loadingFilterTable2 = false
        //     })

        this.loadingFilterTable3 = true
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/gender/area' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMember + '&education_category_id=' + education_category + '&type_city=' + city_type + '&occupation=' + occupation)
            .then((res) => {
              this.membersGenderArea = res.body
              for (var i = 0; i < this.membersGenderArea.length; i++) {
                this.membersGender.count_male = this.membersGender.count_male + this.membersGenderArea[i].count_male
                this.membersGender.count_female = this.membersGender.count_female + this.membersGenderArea[i].count_female
              }
              this.genderChartSeries = []
              this.genderChartSeries.push(
                  this.membersGender.count_male
              )
              this.genderChartSeries.push(
                  this.membersGender.count_female
              )
              this.loadingFilterTable3 = false
            })

        this.loadingFilterTable4 = true
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/gender' + '?date_to=' + this.GenderDateTo + '&date_from=' + this.GenderDateFrom + '&area_id=' + this.regionGender + '&gender=' + genderMentor + '&age_from=' + ageMentorFrom + '&age_to=' + ageMentorTo)
            .then((res) => {
              this.mentorsGenderArea = res.body.regions
              this.genderChartMentorsSeries = []
              for (var i = 0; i < this.mentorsGenderArea.length; i++) {
                this.mentorsGender.count_male = this.mentorsGender.count_male + this.mentorsGenderArea[i].count_male
                this.mentorsGender.count_female = this.mentorsGender.count_female + this.mentorsGenderArea[i].count_female
              }
              this.genderChartMentorsSeries.push(
                  this.mentorsGender.count_male
              )
              this.genderChartMentorsSeries.push(
                  this.mentorsGender.count_female
              )
              this.loadingFilterTable4 = false
            })
      }
    },
    filterApplication(id = null, clear = true) {
      var is_reserve = 0
      var is_active = 0
      if (this.winnersAgeFilter == 'winners') {
        is_reserve = 0
        is_active = 0
      } else if (this.winnersAgeFilter == 'is_reserve') {
        is_reserve = 1
        is_active = 0
      } else if (this.winnersAgeFilter == 'is_active') {
        is_reserve = 0
        is_active = 1
      }


      if (this.medalDateTo == null) {
        this.medalDateTo = ''
      }
      if (this.medalDateFrom == null) {
        this.medalDateFrom = ''
      }
      if (this.regionTogle == id || id.length > 2) {
        this.regionTogle = ''
        id = ''
      } else {
        this.regionTogle = id
      }
      this.applicationsToggle = []
      if (clear == true) {
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/age?date_from=' + this.medalDateFrom + "&date_to=" + this.medalDateTo + '&is_reserve=' + is_reserve + '&is_active=' + is_active)
            .then((res) => {
              this.applications = res.body.regions
              for (var i = 0; i < this.applications.length; i++) {
                var peopleCount = 0
                for (var ageIndex = 0; ageIndex < this.applications[i].ages.length; ageIndex++) {
                  peopleCount = peopleCount + this.applications[i].ages[ageIndex].count_winners
                }
                this.applications[i].agesCount = peopleCount
              }
              for (var x = 0; x < this.applications.length; x++) {
                this.applicationsToggle.push(false)
              }

            })

        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/program?date_from=' + this.medalDateFrom + "&date_to=" + this.medalDateTo + '&is_reserve=' + is_reserve + '&is_active=' + is_active)
            .then((res) => {
              var arrWin = [0, 0, 0]
              var arrRes = [0, 0, 0]
              var arrActive = [0, 0, 0]
              for (var i = 0; i < res.body.length; i++) {
                arrRes[0] = arrRes[0] + res.body[i].program_levels[2].count_reserved
                arrRes[1] = arrRes[1] + res.body[i].program_levels[1].count_reserved
                arrRes[2] = arrRes[2] + res.body[i].program_levels[0].count_reserved

                arrActive[0] = arrActive[0] + res.body[i].program_levels[2].count_confirmed
                arrActive[1] = arrActive[1] + res.body[i].program_levels[1].count_confirmed
                arrActive[2] = arrActive[2] + res.body[i].program_levels[0].count_confirmed

                arrWin[0] = arrWin[0] + res.body[i].program_levels[2].count_winners
                arrWin[1] = arrWin[1] + res.body[i].program_levels[1].count_winners
                arrWin[2] = arrWin[2] + res.body[i].program_levels[0].count_winners
              }


              arrWin = arrWin.map(function (num) {
                if (isNaN(num)) {
                  return 0
                } else {
                  return num
                }
              })
              arrRes = arrRes.map(function (num) {
                if (isNaN(num)) {
                  return 0
                } else {
                  return num
                }
              })
              arrActive = arrActive.map(function (num) {
                if (isNaN(num)) {
                  return 0
                } else {
                  return num
                }
              })


              this.Winnerseries = arrWin
              this.Reservseries = arrRes
              this.Activeseries = arrActive


            })
      }
    },
    kpiSearchItem(id) {
      for (var x = id - 1; x < id; x++) {
        this.currentUserKpi = id
        var arrPoint = []
        var namesArray = []
        var arrDirections = []
        for (var y = 0; y < this.kpi[x].directions.length; y++) {
          this.chartOptionsKpi.yaxis.max = this.kpi[x].plan_kpi
          arrPoint.push(this.kpi[x].directions[y].progress)

          if (this.locale == 'ru') {
            arrDirections.push(this.kpi[x].directions[y].title_ru)
          }
          if (this.locale == 'kz') {
            arrDirections.push(this.kpi[x].directions[y].title_kz)
          }
          arrDirections = [...new Set(arrDirections)];
        }
        for (var el = 0; el < arrDirections.length; el++) {
          this.chartOptionsKpi.xaxis.categories.push(arrDirections[el])
        }
        namesArray.push({
          'name': this.kpi[x].name,
          'data': arrPoint
        })
      }
      this.seriesKpi = namesArray
      this.currentKpi = this.kpi[id - 1].current_kpi
    },
    kpiSearch(page = 1, id = 1) {
      this.kpiLoading = true
      if (page <= this.kpiMeta.last_page && page >= 1) {
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/kpi?name=' + this.kpiFio + '&page=' + page)
            .then((res) => {
              this.currentUserKpi = id
              this.kpiCurrentPage = page
              this.chartOptionsKpi.xaxis.categories = []
              var arrPoint = []
              var namesArray = []
              var arrDirections = []
              this.kpi = res.body.data
              this.kpiMeta = res.body.meta


              for (var x = 0; x < 1; x++) {
                arrPoint = []
                for (var y = 0; y < res.body.data[x].directions.length; y++) {
                  this.chartOptionsKpi.yaxis.max = res.body.data[x].plan_kpi
                  arrPoint.push(res.body.data[x].directions[y].progress)

                  if (this.locale == 'ru') {
                    arrDirections.push(res.body.data[x].directions[y].title_ru)
                  }
                  if (this.locale == 'kz') {
                    arrDirections.push(res.body.data[x].directions[y].title_kz)
                  }
                  arrDirections = [...new Set(arrDirections)];
                }
                namesArray.push({
                  'name': res.body.data[x].name,
                  'data': arrPoint
                })
              }
              for (var el = 0; el < arrDirections.length; el++) {
                this.chartOptionsKpi.xaxis.categories.push(arrDirections[el])
              }
              this.seriesKpi = namesArray
              this.currentKpi = this.kpi[id - 1].current_kpi
              this.kpiLoading = false
            })
      }
    },
    filterDirection(clear = false) {
      if (clear == true) {
        this.DirectionReg = ''
        this.DirectionDateFrom = this.yearFrom
        this.DirectionDateTo = this.yearTo
      }

      if (this.DirectionDateTo == null) {
        this.DirectionDateTo = ''
      }
      if (this.DirectionDateFrom == null) {
        this.DirectionDateFrom = ''
      }
      if (this.DirectionReg == 0) {
        this.DirectionReg = ''
      }
      this.$http.get(window.medalElbasy + '/api/v1/dashboard/tasks/directions?area_id=' + this.DirectionReg + "&date_to=" + this.DirectionDateTo + "&date_from=" + this.DirectionDateFrom)
          .then((res) => {
            this.directions = res.body
            for (var i = 0; i < this.directions.length; i++) {
              this.directions[i].allCount = this.directions[i].tasks.count_new + this.directions[i].tasks.count_on_moving + this.directions[i].tasks.count_moved + this.directions[i].tasks.count_on_confirmation + this.directions[i].tasks.count_confirmed + this.directions[i].tasks.count_refused + this.directions[i].tasks.count_waited + this.directions[i].tasks.count_not_performed
            }
          })
    },
    statsFilter(page = 1) {

      if (page <= this.statsMeta.last_page && page >= 1) {
        this.loadingStats = true
        this.$http.get(window.medalElbasy + '/api/v1/dashboard/mentors/tasks/statistic?page=' + page + `&date_to=${this.TaskDateTo}&date_from=${this.TaskDateFrom}&sort_name=${this.mentorsDirection.sort_name}&sort_area=${this.mentorsDirection.sort_area}`)
            .then((res) => {
              this.loadingStats = false
              this.statsCurrentPage = page
              this.mentorsArea = res.body.data
              this.statsMeta = res.body.meta
            })
      }
    },

    mentorsDirection(type) {
      if (type == 'sort_name') {
        if (this.mentorsDirection.sort_name == 'asc') {
          this.mentorsDirection.sort_name = 'desc'
          this.mentorsDirection.sort_area = ''
        } else {
          this.mentorsDirection.sort_name = 'asc'
          this.mentorsDirection.sort_area = ''
        }
      } else if (type == 'sort_area') {
        if (this.mentorsDirection.sort_area == 'asc') {
          this.mentorsDirection.sort_area = 'desc'
          this.mentorsDirection.sort_name = ''
        } else {
          this.mentorsDirection.sort_area = 'asc'
          this.mentorsDirection.sort_name = ''
        }
      }


      this.$http.get(window.medalElbasy + `/api/v1/dashboard/mentors/tasks/statistic?date_to=${this.TaskDateTo}&date_from=${this.TaskDateFrom}&sort_name=${this.mentorsDirection.sort_name}&sort_area=${this.mentorsDirection.sort_area}`)
          .then((res) => {
            this.mentorsArea = res.body.data
            this.statsMeta = res.body.meta
          })
    },

    ageChartFilter() {
      this.agesCharColumns = []
      this.seriesAge = []
      this.chartLoad = true
      var currenLocaleAgeChart = this.currenLocaleAgeChart
      if (currenLocaleAgeChart == 0) {
        currenLocaleAgeChart = ''
      }
      this.chartOptionsAgeLoad = true
      this.$http.get(window.medalElbasy + '/api/v1/dashboard/members/age?area_id=' + currenLocaleAgeChart).then((res) => {
        var membersArray = []
        var winnersArray = []
        var reservedArray = []
        var rejectedArray = []
        this.chartOptionsAge.xaxis.categories = []
        for (var i = 0; i < (res.body.total.ages.length); i++) {
          this.chartOptionsAge.xaxis.categories.push(res.body.total.ages[i].age + ' ' + this.$t('МедальЕлбасы.лет'))
          if (this.regionAgeFilterRole == 0 || this.regionAgeFilterRole == '' || this.regionAgeFilterRole == 2) {
            membersArray.push(res.body.total.ages[i].count_members)
          }
          if (this.regionAgeFilterRole == 0 || this.regionAgeFilterRole == '' || this.regionAgeFilterRole == 3) {
            winnersArray.push(res.body.total.ages[i].count_winners)
          }
          if (this.regionAgeFilterRole == 0 || this.regionAgeFilterRole == '' || this.regionAgeFilterRole == 1) {
            reservedArray.push(res.body.total.ages[i].count_reserved)
          }
          if (this.regionAgeFilterRole == 0 || this.regionAgeFilterRole == '' || this.regionAgeFilterRole == 4) {
            rejectedArray.push(res.body.total.ages[i].count_rejected)
          }
        }

        this.seriesAge.push(
            {
              name: this.$t('МедальЕлбасы.Активныеучастники'),
              data: membersArray
            }
        )


        this.seriesAge.push(
            {
              name: this.$t('МедальЕлбасы.Победители'),
              data: winnersArray
            }
        )


        this.seriesAge.push(
            {
              name: this.$t('МедальЕлбасы.Резерв'),
              data: reservedArray
            }
        )


        this.seriesAge.push(
            {
              name: this.$t('МедальЕлбасы.Отклонен'),
              data: rejectedArray
            }
        )
        this.chartLoad = false
        this.chartOptionsAgeLoad = false
      })
    }
  }
}


</script>
<style lang="css" scoped>
.title {
  font-size: 20px;
}

.tasks__row {
  flex-wrap: wrap;
}

.panel {
  user-select: none;
}

.panel-collapse {
  padding-bottom: 20px;
  overflow: auto;
}

.panel-collapse::-webkit-scrollbar-track {
  border-radius: 4px;
  background: rgba(15, 61, 95, 0.15);
}

.panel-collapse::-webkit-scrollbar {
  height: 8px;
  background: rgba(15, 61, 95, 0.15);
}

.panel-collapse::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #277675;
}

.mb-stat-region__table-block::-webkit-scrollbar-thumb,
.mb-rating__table-block--schools::-webkit-scrollbar-thumb {
  background: #09B1B0;
  border-radius: 4px;
}

@media (max-width: 1603px) {
  .kpi__table-block {
    width: 100%;
  }

  .kpi__diagramm {
    margin-left: auto;
  }

  .kpi__row {
    flex-wrap: wrap;
  }

  .kpi__table {
    min-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .nav-tabs {
    flex-direction: column;
  }

  .age__selects {
    margin-left: auto;
    justify-content: end;
  }

  .tasks-table .tasks-table__title {
    min-width: 231px !important;
  }

  .applications__chart {
    width: 100%;
    justify-content: center;
  }

  .applications__region {
    margin: auto;
  }

  .applications__card {
    width: 100%;
  }

  .applications__row {
    flex-direction: column-reverse;
  }

  .select {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .chartKpi {
    margin: 1rem auto auto;
  }

  .kpi__diagramm {
    width: 100%;
  }

  .tasks-card {
    width: 200px;
  }


}

@media (max-width: 767px) {
  .kpi__header {
    flex-direction: column;
  }

  .el-pagination {
    margin-top: 1rem;
  }

  .search {
    width: 100%;
  }
}
</style>
